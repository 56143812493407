<template>
    <el-dialog id="additionalPayment" :visible.sync="show" :width="screenWidth > 991 ? '50%' : '90%'" :before-close="handleClose">
        <div class="credit-card-container" style="max-width: none;">
            <div class="credit-card" style="max-width: none;">
                <div class="payment">
                    <div class="title-payment" style="text-align: center; margin-top: 1rem;">{{ $localization("web-additional-payment-you-are-satisfied", "Görüşmeden memnun kaldıysanız ödeme yapabilirsiniz.") }}</div>
                    <!--<p class="text-payment">{{ $localization("web-interview-please-enter-card-info") }}</p>-->
                </div>
                <el-radio-group v-model="payWithCard" v-if="user.PaymentMethods.length > 0">
                    <el-radio-button :label="true">{{ $localization("web-interview-saved-cards") }} <span style="background: unset" v-if="user.PaymentMethods != null && user.PaymentMethods.length > 0">({{
                        user.PaymentMethods.length
                    }})</span></el-radio-button>
                    <el-radio-button :label="false">{{ $localization("web-interview-new-card") }}</el-radio-button>
                </el-radio-group>

                <div class="new-card" v-if="!payWithCard">
                    <div class="title">{{ $localization("web-interview-card-info") }}</div>

                    <div class="form-item">
                        <div class="title">{{ $localization("web-interview-cardholder") }}</div>
                        <el-input class="primary-input" placeholder="..." v-model="form.CardName"></el-input>
                        <span class="error">{{ $errorMessage("cardHolder", validationErrors) }}</span>
                    </div>
                    <div class="form-item">
                        <div class="title">{{ $localization("web-interview-cardno") }}</div>
                        <el-input class="primary-input" v-mask="mask.CardNO" placeholder="**** **** **** ****" v-model="form.CardNO"></el-input>
                        <span class="error">{{ $errorMessage("CardNo", validationErrors) }}</span>
                    </div>

                    <div class="form-container">
                        <div class="form-item" style="width: 50%;">
                            <div class="title">{{ $localization("web-interview-expire-date") }}</div>
                            <el-input class="primary-input" type="text" v-mask="mask.ExpireDate" placeholder="** / **" auto-complete="false" v-model="form.ExpireDate"></el-input>
                            <span class="error">{{ $errorMessage("expireMonth", validationErrors) || $errorMessage("expireYear", validationErrors) }}</span>
                        </div>
                        <div class="form-item" style="width: 50%;">
                            <div class="title">{{ $localization("web-interview-cvv") }}</div>
                            <el-input class="primary-input" type="password" v-mask="mask.CVV" placeholder="***" v-model="form.CVV" autocomplete="off"></el-input>
                            <span class="error">{{ $errorMessage("cvc", validationErrors) }}</span>
                        </div>
                    </div>
                    <div class="form-item">
                        <el-switch v-model="saveCard" :active-text="$localization('web-interview-save-my-card')" active-color="#234A98" :inactive-text="$localization('web-interview-dont-save-my-card')"> </el-switch>
                    </div>
                    <div id="hidden2" style="display: none"></div>
                </div>


                <div class="card-list" v-if="payWithCard">
                    <div class="title">{{ $localization("web-interview-my-saved-cards") }}</div>
                    <ul class="credit-card-list" style="min-height: 350px;">
                        <li class="item" v-for="(card, index) in user.PaymentMethods" :key="index" :class="{ active: activeCard == card.Id ? true : false }" @click="activeCard = card.Id">
                            <div class="icon">
                                <i class="el-icon-circle-check"></i>
                            </div>
                            <div class="card-info">
                                <div class="title">
                                    {{ card.Title }}
                                </div>
                                <div class="card-bin">
                                    <div class="period-container">
                                        <div class="period">
                                            {{ card.Bin.slice(0, 4) }}
                                        </div>
                                        <div class="period">
                                            {{ card.Bin.slice(4, 6) }}
                                            <div class="icon-period" v-for="i in 2" :key="i"></div>
                                        </div>
                                        <div class="period">
                                            <div class="icon-period" v-for="i in 4" :key="i"></div>
                                        </div>
                                        <div class="period">
                                            <div class="icon-period" v-for="i in 4" :key="i"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-img">
                                <img src="/img/cardassocation/mastercard.svg" v-if="card.CardAssociation == 'MASTER_CARD'" alt="" />
                                <img src="/img/cardassocation/visa.svg" v-if="card.CardAssociation == 'VISA'" alt="" />
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="payment-bottom">
                    <img src="/img/iyzico-banner.svg" alt="Iyzico Banner" height="24" style="margin-top: 10px;" />

                    <div class="payment-info">
                        <div class="item">
                            <div class="title">{{ $localization("web-interview-price") }}</div>
                            <div class="desc">{{ ((appointmentNoDiscountPrice * 100) / (100 + appointmentTaxRate)).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
                        </div>
                        <div class="item">
                            <div class="title">{{ $localization("web-interview-tax") }}</div>
                            <div class="desc">{{ (appointmentNoDiscountPrice - ((appointmentNoDiscountPrice * 100) / (100 + appointmentTaxRate))).toFixed(2) }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
                        </div>
                        <div class="item">
                            <div class="title" style="color: #234A98">{{ $localization("web-interview-total-price") }}</div>
                            <div class="desc" style="color: #234A98">{{ appointmentNoDiscountPrice }} {{ $store.state.auth.language == "tr" ? "TL" : "USD" }}</div>
                        </div>
                    </div>

                    <el-button class="primary-btn start-pay" @click="makeAdditionalPayment()" :disabled="paymentLoading" :loading="paymentLoading">
                        <div class="icon" v-if="!paymentLoading"></div>
                        <span v-if="!paymentLoading">{{ $localization("web-interview-pay") }}</span>
                    </el-button>
                </div>

            </div>



        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        appointmentId: {
            type: String,
            required: true,
        },
    },
    async beforeMount() {
        if (this.user.PaymentMethods.length > 0) {
            this.activeCard = this.user.PaymentMethods[0].Id;
        }

        await this.getNoDiscountPrice();
        await this.getTaxRate();
    },

    mounted() {
        this.screenWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            this.screenWidth = window.innerWidth;
        });
    },

    data() {
        return {
            screenWidth: 0,
            show: true,
            appointmentNoDiscountPrice: 0,
            appointmentTaxRate: 0,
            validationErrors: [],
            paymentLoading: false,
            payWithCard: false,
            activeCard: null,
            saveCard: false,
            form: {
                CardName: "",
                CardNO: "",
                CVV: "",
                ExpireDate: "",
            },
            mask: {
                CardNO: "#### #### #### ####",
                ExpireDate: "##/##",
                CVV: "###",
                Identity: "###########",
            },
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    },

    methods: {
        async getNoDiscountPrice() {
            var res = await this.$client.post("/Appointment/GetAppointmentPrice", { ApplyDiscount: false });
            if (!res.data.HasError) {
                this.appointmentNoDiscountPrice = res.data.Data;
            }
        },
        async getTaxRate() {
            var res = await this.$client.post("/Appointment/GetAppointmentTaxRate");
            if (!res.data.HasError) {
                this.appointmentTaxRate = res.data.Data;
            }
        },
        async makeAdditionalPayment() {
            this.ValidationErrors = [];
            this.paymentLoading = true;

            if (!this.payWithCard) {
                var expireArray = this.form.ExpireDate.split("/");
                let paymentObj = {
                    AppointmentId: this.appointmentId,
                    AppointmentRequestFrom: 0,
                    CardNo: this.form.CardNO.replace(/\s/g, ""),
                    CardHolder: this.form.CardName,
                    ExpireYear: expireArray[1],
                    ExpireMonth: expireArray[0],
                    Cvc: this.form.CVV,
                    SaveCard: this.saveCard,
                };
                var res = await this.$client.post("/Appointment/AdditionalPaymentNewCard", paymentObj);
                if (res.data.HasError) {
                    this.validationErrors = res.data.ValidationErrors;
                    this.$message.error(res.data.Message);
                } else {
                    //3D Secure threedHtmlContent Open
                    if (res.data.Data.goToThreedHtmlPage) {
                        window.location.href = res.data.Data.ThreedHtml;
                    } else {
                        window.jQuery("#hidden2").html(res.data.Data.ThreedHtml);
                        window.jQuery("#hidden2").find("form").submit();
                    }
                    this.show = false;
                }
            } else {
                let res = await this.$client.post("/Appointment/AdditionalPaymentSavedCard", {
                    PaymentMethodId: this.activeCard,
                    AppointmentRequestFrom: 0,
                    AppointmentId: this.appointmentId,
                });
                if (res.data.HasError) {
                    this.validationErrors = res.data.ValidationErrors;
                    this.$message.error(res.data.Message);
                } else {
                    this.$message({
                        type: "success",
                        message: this.$localization("web-additional-payment-thanks-for-payment", "Ödemeniz için teşekkürler."),
                    });
                    this.show = false;
                    this.$router.push("/kullanici");
                }
            }
            this.paymentLoading = false;
        },
        handleClose() {
            this.show = false;
            this.$router.push({ path: "/kullanici" });
        },
    },
};
</script>

<style lang="less">
#additionalPayment {

    .credit-card-container {
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: row;
        gap: 35px;
        justify-content: space-evenly;
        min-width: 340px; //16/06/2023 HS !!! mobil için min-width eklendi

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        &>.card-details {
            max-width: 360px;
            width: 100%;
            border-radius: 10px;
            box-shadow: 4px 4px 24px rgba(196, 196, 196, 0.3);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;

            //16/06/2023 HS !!! mobil için ayar eklendi
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }

            &>.top {
                width: 100%;
                border-radius: 10px 10px 0 0;
                padding: 25px;
                background-color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                padding-bottom: 60px;
                box-shadow: 4px 4px 24px rgba(196, 196, 196, 0.3);

                .header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        color: #44566c;
                        line-height: 24px;
                    }
                }
            }

            &>.bottom {
                padding: 25px;
                width: 100%;
                background-color: #f8fafb;
                flex-direction: column;
                gap: 35px;
                display: flex;
                border-radius: 0 0 10px 10px;
            }
        }

        .credit-card {
            padding: 25px 25px;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            max-width: 360px;
            width: 100%;
            background-color: #eff4f8;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
                max-width: 100%;
                width: 100%;
                height: auto !important;
                border-radius: 0;
            }

            .el-radio-group {
                width: 100%;
                align-self: center;
                display: flex;

                label {
                    &.is-active {
                        span {
                            color: white !important;
                        }
                    }

                    width: 50%;

                    span {
                        border: none !important;
                        box-shadow: none !important;
                        width: 100%;
                        background-color: #eff4f8;
                        color: #234A98 !important;
                    }
                }
            }

            .card-list {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                height: 100%;

                &>.title {
                    color: #44566c;
                    font-weight: bold;
                    font-size: 20px;
                }

                .policy {
                    margin-top: auto;
                    margin-bottom: 1rem;
                }
            }

            .new-card {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .title {
                    color: #44566c;
                    font-weight: bold;
                    font-size: 20px;
                }

                .form-container {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                }

                .form-item,
                .form-container>.form-item {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .title {
                        color: #8697a8;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
            }
        }

        .payment {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .title-payment {
                color: #44566c;
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
            }

            .text-payment {
                padding: 0;
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #8697a8;
            }
        }
    }

    .payment-bottom {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .payment-info {
            display: flex;
            justify-content: space-around;
            background-color: #f8fafb;
            padding: 10px;
            border-radius: 11px !important;
            border: 2px solid #234A98 !important;
        }

    }

    .item {
        flex-direction: row;
        display: flex;
        gap: 1rem;
        align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
        }

        .title {
            font-size: 15px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            color: #8697a8 !important;
        }

        .desc {
            font-weight: 500 !important;
            font-size: 15px !important;
            line-height: 18px !important;
            color: #44566c !important;
        }
    }
}
</style>