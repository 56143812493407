export default {
  getAppointments: (state) => state.appointments,
  getCurrentAppointment: (state) => state.currentAppointment,
  getAppoinmentsFilter: (state) => state.filter,
  getCurrentDoctor: (state) => state.currentDoctor,
  getAppointmentPrice: (state) => state.appointmentPrice,
  getDiscountAppointmentPrice: (state) => state.discountAppointmentPrice,
  getIsFree: (state) => state.isFree,
  getTaxRate: (state) => state.taxRate,
  getSmsShowDialog: (state) => state.SmsShowDialog,
  getIsMobilePaymentEnabled: (state) => {
    state.isMobilePaymentEnabled;
  },
};
