import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
import * as MutationNames from "../mutation-names";

export default {
  async getFiles(context, filter) {
    var res = await client.post("/FileManager/ListFiles", filter);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit(MutationNames.SET_FILES, res.data.Data);
    }
  },
  async deleteFiles(context, payload) {
    var res = await client.post("/FileManager/DeleteFiles", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit(MutationNames.DELETE_FILES, payload.IDs);
    }
    return res.data;
  },
  async getFileCategories(context, payload) {
    var res = await client.post("/FileManager/ListFileCategories", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit(MutationNames.SET_FILE_CATEGORIES, res.data.Data);
    }
  },
  async deleteFileCategory(context, payload) {
    var res = await client.post("/FileManager/DeleteFileCategory", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit(MutationNames.DELETE_FILE_CATEGORY, payload.Id);
    }
  },
  async addUpdateFileCategory(context, payload) {
    context.commit(MutationNames.SET_FILE_VALIDATION_ERRORS, []);
    var res = await client.post("/FileManager/AddUpdateFileCategory", payload);
    if (res.data.HasError) {
      context.commit(MutationNames.SET_FILE_VALIDATION_ERRORS, res.data.ValidationErrors);
      Message.error(res.data.Message);
    } else {
      context.commit(MutationNames.ADD_UPDATE_FILE_CATEGORY, res.data.Data.Category);
    }
    return res.data;
  },
};
