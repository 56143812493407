<template>
  <div id="screen">
    <div class="left">
      <div v-if="currentAppointment.Status == 3 && user.Role == 2" class="end-appointment" style="text-align: center">
        <h2>{{ $localization("web-meetingroom-meet-ended") }}</h2>
        <p>{{ $localization("web-meetingroom-after-completing-yourpatient-procedures") }}</p>
        <el-button @click="() => { this.$router.push('/'); }">{{ $localization("web-meetingroom-return-to-home") }}</el-button>
      </div>
      <div v-if="currentAppointment.Status == 3 && user.Role == 1" class="end-appointment">
        <h2>{{ $localization("web-meetingroom-meet-ended") }}</h2>
      </div>
      <template v-if="currentAppointment && currentAppointment.Status != 3">
        <Peers v-if="roomClientReady" :room-client="roomClient" />
      </template>

      <!-- <Peer />-->
      <Control v-if="roomClientReady" :room-client="roomClient" v-model="rateDialog" />
    </div>

    <!--#region 16/06/2023 HS !!! mobil için sohbeti yukarı aşağı kaydırma eklendi -->
    <div :class="{ 'slide-up': showDiv }" id="slidingDiv">
      <div class="chat-open-button">
        <button @click="toggleDiv">
          <span class="arrow" :class="{ 'active': !showDiv }">
            <span></span>
            <span></span>
          </span>
        </button>
      </div>
      <div class="right">
        <ClientTab></ClientTab>
      </div>
    </div>
    <!--#region-->

    <!--
    <div class="right">
      <ClientTab></ClientTab>
    </div>-->

    <AdditionalPaymentForm v-if="isFree && additionalPaymentIsClear && additionalPaymentDialog" :appointmentId="this.$route.params.id"></AdditionalPaymentForm>

    <el-dialog custom-class="rate-dialog" :visible.sync="rateDialog" :close-on-click-modal="false" max-width="90%" :before-close="handleClose">
      <div class="container">
        <div class="title">{{ $localization("web-meetingroom-rate-meet") }}</div>
        <div class="input-container">
          <div class="title">{{ $localization("web-meetingroom-doctor") }}</div>
          <div class="content" v-if="currentAppointment.DoctorUser != null">{{ currentAppointment.DoctorUser.Title }}</div>
          <span class="error"> </span>
        </div>
        <div class="input-container">
          <div class="title">{{ $localization("web-meetingroom-rate") }}</div>
          <el-input type="textarea" maxlength="450" :rows="2" :placeholder="$localization('web-meetingroom-enter-your-rate')" v-model="rateObj.Notes"> </el-input>
          <span class="error"> {{ $errorMessage("Notes", ValidationErrors) }}</span>
        </div>
        <div class="input-container">
          <div class="title">{{ $localization("web-meetingroom-rate-point") }}</div>
          <el-rate v-model="rateObj.Rate"></el-rate>

          <span class="error" v-if="ValidationErrors.length > 0"> {{ $errorMessage("Rate", ValidationErrors) }} </span>
        </div>

        <div class="input-container">
          <div class="title">{{ $localization("web-meetingroom-recommend-elra") }}</div>
          <div class="radio-group">
            <el-radio v-model="rateObj.Recommendation" :label="true">{{ $localization("web-meetingroom-yes") }}</el-radio>
            <el-radio v-model="rateObj.Recommendation" :label="false">{{ $localization("web-meetingroom-no") }}</el-radio>
          </div>
          {{ $errorMessage("Recommendation", ValidationErrors) }}
        </div>
        <div class="input-container">
          <div class="title">{{ $localization("web-meetingroom-where-did-you-find-us") }}</div>
          <el-input type="textarea" maxlength="450" :rows="2" placeholder="..." v-model="rateObj.HowYouReach"> </el-input>
          <!-- {{ $errorMessage("Recommendation", ValidationErrors) }} -->
        </div>

        <div class="button-container">
          <el-button class="secondarary-btn" @click="handleClose()"> {{ $localization("web-meetingroom-cancel") }} </el-button>
          <el-button class="primary-btn btn-shadow" @click="rateSend()">
            <i class="icon-send"></i>
            {{ $localization("web-meetingroom-send") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Peer from "./screen/Peer.vue";
import Control from "./screen/Control.vue";
import ClientTab from "./screen/ClientTab.vue";

import UrlParse from "url-parse";
// import Stats from '~/components/Stats'
import Peers from "./screen/Peers";

import RoomClient from "../../utils/RoomClient";
import deviceInfo from "../../utils/deviceInfo";
import randomString from "../../utils/randomString";
import $ from "jquery";
import AdditionalPaymentForm from "../AdditionalPaymentForm.vue";

export default {
  components: { Control, ClientTab, AdditionalPaymentForm, Peers },
  data() {
    return {
      rateObj: {
        AppointmentId: this.$route.params.id,
        Notes: null,
        Rate: 0,
        Recommendation: true,
        HowYouReach: null,
      },
      permissionForm: {
        IsCameraApproved: false,
        IsMicApproved: false,
        Platform: 0,
        Version: null,
      },
      rateDialog: false,
      roomClient: null,
      ValidationErrors: [],
      roomClientReady: false,
      getCurrentAppointmentInterval: null,
      timeout: null,
      showDiv: false,//HS 14/06/2023 !!! mobil için chatin açık olup olmadığını tutan değişken
      isFree: false,
      additionalPaymentDialog: false,
    };
  },
  computed: {
    me() {
      return this.$store.state.room.me;
    },
    amActiveSpeaker() {
      return this.$store.state.room.me.id === this.$store.state.room.room.activeSpeakerId;
    },
    room() {
      return this.$store.state.room.room;
    },
    user() {
      return this.$store.getters.getUser;
    },
    store_socket() {
      return this.$store.getters.getSocket;
    },
    currentAppointment() {
      return this.$store.getters.getCurrentAppointment;
    },
    additionalPaymentIsClear() {
      return (this.$store.getters.getCurrentAppointment.PaymentInfo.AdditionalPrice === null || this.$store.getters.getCurrentAppointment.PaymentInfo.AdditionalPrice === 0)
    },
  },
  beforeMount() {
    this.socketActions();
  },
  async mounted() {
    // await navigator.permissions.query({ name: "camera" }).then((res) => {
    //   if (res.state == "granted") {
    //     this.permissionForm.IsCameraApproved = true;
    //   }
    //   if (res.state == "denied") {
    //     this.permissionForm.IsCameraApproved = false;
    //   }
    // });
    // await navigator.permissions.query({ name: "microphone" }).then((res) => {
    //   if (res.state == "granted") {
    //     this.permissionForm.IsMicApproved = true;
    //   }
    //   if (res.state == "denied") {
    //     this.permissionForm.IsMicApproved = false;
    //   }
    // });
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        console.log("microphone success", stream);
        this.permissionForm.IsMicApproved = true;
      })
      .catch((err) => {
        console.log("microphone error", err);
        this.permissionForm.IsMicApproved = false;
      });
    await navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => {
        console.log("camera success", stream);
        this.permissionForm.IsCameraApproved = true;
      })
      .catch((err) => {
        console.log("camera error", err);
        this.permissionForm.IsCameraApproved = false;
      });

    await this.$store.dispatch("setPermissionCameraAndMicrophone", this.permissionForm);

    window.localStream = new MediaStream();
    const urlParser = new UrlParse(window.location.href, true);
    const peerId = randomString(8).toLowerCase();
    var isKid = false;
    let roomId = this.$route.params.id;
    let displayName = this.$store.state.auth.user.FirstName + " " + this.$store.state.auth.user.LastName;

    let title = this.user.Specialist;
    if (!this.currentAppointment.Permission) {
      // roomId += "-norecord";
    }
    if (this.currentAppointment.User != null && this.currentAppointment.User.Kid != null && this.currentAppointment.User.Id == this.$store.state.auth.user.ID) {
      isKid = true;
      displayName = this.currentAppointment.User.Kid.FirstName + " " + this.currentAppointment.User.Kid.LastName;
    }

    const handler = urlParser.query.handler;
    const useSimulcast = urlParser.query.simulcast !== "false";
    const useSharingSimulcast = urlParser.query.sharingSimulcast !== "false";
    const forceTcp = urlParser.query.forceTcp === "true";
    //const produce = urlParser.query.produce !== 'false'
    const consume = urlParser.query.consume !== "false";
    const forceH264 = urlParser.query.forceH264 === "true";
    const forceVP9 = urlParser.query.forceVP9 === "true";
    const svc = urlParser.query.svc;
    const datachannel = urlParser.query.datachannel !== "false";
    const faceDetection = urlParser.query.faceDetection === "true";
    const externalVideo = urlParser.query.externalVideo === "true";

    // Get the effective/shareable Room URL.
    const roomUrlParser = new UrlParse(window.location.href, true);

    delete roomUrlParser.hash;

    const roomUrl = roomUrlParser.toString();
    let displayNameSet = true;

    // Get current device info.
    const device = deviceInfo();

    this.$store.commit("setRoomUrl", { roomUrl });

    this.$store.commit("setFaceDetection", { flag: faceDetection });
    let specialist = null;
    if (this.user.Role == 2) {
      specialist = this.user.Specialist;
    }
    this.$store.commit("setMe", {
      peerId,
      displayName,
      displayNameSet,
      device,
      title: specialist,
      jwtToken: localStorage.getItem("token"),
      isChild: isKid,
    });

    this.roomClient = new RoomClient({
      roomId,
      peerId,
      displayName,
      device,
      handlerName: handler,
      useSimulcast,
      useSharingSimulcast,
      forceTcp,
      produce: true,
      consume,
      forceH264,
      forceVP9,
      svc,
      datachannel,
      externalVideo,
      store: this.$store,
      title: title,
      jwtToken: localStorage.getItem("token"),
      isChild: isKid,
    });

    this.roomClient.join();

    this.roomClientReady = true;
    await this.setCurrentAppointment();
    this.getCurrentAppointmentInterval = setInterval(() => {
      this.setCurrentAppointment(); //eslint-disable-line
    }, 5000);
    this.timeout = setTimeout(() => {
      console.log("enabling");
      this.roomClient.enableMic();
      this.roomClient.enableWebcam();
    }, 5000);


    this.getAppointmentIsFree();
  },
  //bu metodu async yaparsanız alltki clearInterval patlayacaktır.
  beforeDestroy() {
    window.clearInterval(this.getCurrentAppointmentInterval);
    clearTimeout(this.timeout);
    this.store_socket.off("presence-user-" + this.user.ID + "-appointmentCompleted");
    this.store_socket.emit("subscribe", {
      channel: "presence-user-" + this.user.ID,
      token: "",
    });
    localStream.getTracks().forEach((track) => track.stop()); //eslint-disable-line

    this.stopAllVideos();

    this.$store.state.room.producers = [];
    this.$store.state.room.consumers = [];
    this.$store.state.room.peers = [];

    this.roomClient.disableMic();
    this.roomClient.disableWebcam();
    this.roomClient.disableShare();
    this.roomClient.close();
  },
  watch: {
    store_socket: function () {
      this.socketActions();
    },
    rateDialog(newValue) {
      console.log("aaaaaaaaaaaaa " + newValue);

      if (this.isFree && this.additionalPaymentIsClear) {
        if (newValue == true) { //sonsuz döngüye girmesin diye
          this.rateDialog = false;
          this.additionalPaymentDialog = true;
        }
      }
    }
  },
  methods: {
    socketActions() {
      if (this.store_socket != null) {
        this.store_socket.off("presence-user-" + this.user.ID + "-appointmentCompleted");
        this.store_socket.on("presence-user-" + this.user.ID + "-appointmentCompleted", (event) => {
          if (event.data.Appointment.ID == this.$route.params.id) {
            this.Appointment = event.data.Appointment;
            if (this.Appointment.FastComplete && this.Appointment.FastCompleteUserId != this.user.ID) {
              //karşı taraf sonlandırmış
              this.$message.info(this.$localization("web-meetingroom-meet-ended-by-other-member-redirecting"));
              setTimeout(() => {
                if (this.user.Role == 1) {
                  if (this.rateDialog != true) {
                    this.rateDialog = true;
                  }
                }
              }, 1000);
            }
          }
          this.roomClient.muteMic();
          this.roomClient.disableWebcam();
        });
      }
    },
    handleClose() {
      this.rateDialog = false;
      if (!this.isFree || !this.additionalPaymentIsClear) {
        this.$router.push({ path: "/kullanici" });
      }
      else {
        this.additionalPaymentDialog = true;
      }
    },
    async rateSend() {
      var res = await this.$client.post("/Appointment/Rate", this.rateObj);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(this.$localization("web-meetingroom-rate-sent-successfuly"));
        this.rateDialog = false;

        if (!this.isFree || !this.additionalPaymentIsClear) {
          setTimeout(() => {
            this.$router.push("/kullanici");
          }, 2000);
        }
        else {
          this.additionalPaymentDialog = true;
        }
      }
    },
    async setCurrentAppointment() {
      //eslint-disable-line
      var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id }); //eslint-disable-line
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        setTimeout(async () => {
          this.$router.push("/");
        }, 2000);
      } else {
        if (res.data.Data.Appointment.Status == 3 && this.currentAppointment.Status != 3) {
          localStream.getTracks().forEach((track) => track.stop()); //eslint-disable-line
          this.stopAllVideos();
          this.$store.state.room.producers = [];
          this.$store.state.room.consumers = [];
          this.$store.state.room.peers = [];
          this.roomClient.disableMic();
          this.roomClient.disableWebcam();
          this.roomClient.disableShare();
          this.roomClient.close();
          window.clearInterval(this.getCurrentAppointmentInterval);
        }
        this.$store.commit("setCurrentAppointment", res.data.Data.Appointment);
      }
    },
    stopAllVideos() {
      try {
        var id = "";
        var id2 = "";
        var a = "";

        for (var i = 0; i < this.$store.state.room.peers.length; i++) {
          id = "#video-" + this.$store.state.room.peers[i].id;
          id2 = "#audio-" + this.$store.state.room.peers[i].id;

          a = $(id)[0];
          if (a != null && a != undefined) {
            const stream = a.srcObject;
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }

          a = $(id2)[0];
          if (a != null && a != undefined) {
            const stream = a.srcObject;
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }
        }

        id = "#video-" + this.$store.state.room.me.id;
        id2 = "#audio-" + this.$store.state.room.me.id;

        a = $(id)[0];
        if (a != null && a != undefined) {
          const stream = a.srcObject;
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }

        a = $(id2)[0];
        if (a != null && a != undefined) {
          const stream = a.srcObject;
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    toggleParticipantsVideo() {
      this.me.audioOnly ? this.roomClient.disableAudioOnly() : this.roomClient.enableAudioOnly();
    },

    toggleMute() {
      this.me.audioMuted ? this.roomClient.unmuteAudio() : this.roomClient.muteAudio();
    },
    toggleDiv() {//06/16/2023 HS !!! mobil için chati açıp kapatan fonksiyon
      this.showDiv = !this.showDiv;
    },
    async getAppointmentIsFree() {
      var res = await this.$client.post("Appointment/GetAppointmentIsFree");
      this.isFree = res.data.Data;
    },
  },
};
</script>

<style lang="less">
//#region 06/16/2023 HS !!! mobil için chat kısmının css/less dosyası
#slidingDiv {
  z-index: 2;

  @media screen and (min-width:1080px) {
    transform: translateY(0);
  }

  @media screen and (max-width:1080px) {
    bottom: -480px;
    right: 12px;
    left: 12px;
    transition: transform 0.3s ease-in-out;
    position: fixed;
  }

  .chat-open-button {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1080px) {
      display: none;
    }

    button {
      height: 40px;
      width: 60px;
      background-color: #dfe2e6;
      border: 1px;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
    }

    button:hover {
      background-color: #ced3d9;
    }

    .arrow {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      position: relative;
      //margin: 2px 1rem 0px 1rem;

      span {
        top: .5rem;
        position: absolute;
        width: .75rem;
        height: .1rem;
        background-color: #000000;
        display: inline-block;
        transition: all .2s ease;

        &:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        &:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }
      }

      &.active {

        span {
          &:first-of-type {
            transform: rotate(-45deg);
          }

          &:last-of-type {
            transform: rotate(45deg);
          }
        }
      }
    }

    animation: moveUpDown 2s infinite ease-in-out;
  }

  @keyframes moveUpDown {

    0%,
    100% {
      transform: translateY(5px);
    }

    50% {
      transform: translateY(12px);
    }
  }
}

.slide-up {
  transform: translateY(-90%);
}


//#endregion
</style>
