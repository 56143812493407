import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./helpers/axiosHelpers";
import router from "./router";
import store from "./store";
//import './assets/css/element-ui-with-theme/index.css'
import ElementUI from "element-ui";
import PortalVue from "portal-vue";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import VueMask from "v-mask";
import jQuery from "jquery";
import VueQuilEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import locale from "element-ui/lib/locale/lang/tr-TR";
import VueLottiePlayer from "vue-lottie-player";
import VueCountdownTimer from "vuejs-countdown-timer";
import VuePageTransition from "vue-page-transition";
import OneSignalVue from "onesignal-vue";
import VueCountryCode from "vue-country-code";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueInputUi from "vue-input-ui";
import "vue-input-ui/dist/vue-input-ui.css";
import VueGtag from "vue-gtag";
//import Hotjar from 'vue-hotjar';
import * as ActionNames from "@/store/action-names";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("vue-input-ui", VueInputUi);
Vue.use(VueCountryCode);
Vue.use(VueCountdownTimer);
Vue.use(VueQuilEditor);
Vue.use(VuePageTransition);
Vue.use(VueLottiePlayer);
Vue.use(OneSignalVue);
Vue.use(PortalVue);
Vue.use(ElementUI, { locale });
Vue.use(VueMask);

if (process.env.NODE_ENV === "production") {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
  });

  /*Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: true,
  });*/

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
  });
}
var langCode = ![null, "null"].includes(localStorage.getItem("language")) ? localStorage.getItem("language") : "tr";
store.commit("setLanguage", langCode);
client
  .post("/Localization/GetLocalizationJson", {
    IsWeb: true,
    LanguageCode: langCode,
  })
  .then((response) => {
    //!!! FC 07/05/24++ undefined kontrol edildi cunku bazı browserler null yerıne undefined çeviriyor
    if (localStorage.getItem(`language`) == null || localStorage.getItem(`language`) == undefined) {
      localStorage.setItem(`language`, langCode);
    }
    if (localStorage.getItem("localisation") == null || localStorage.getItem("localisation") == undefined) {
      localStorage.setItem(`localization`, JSON.stringify(response.data));
      store.commit("setLocalizationKeys", response.data);
    }
  })
  .catch((error) => {
    console.log(error);
  });
moment.locale("tr");
Vue.prototype.$moment = moment;

store.dispatch(ActionNames.GET_COUNTRY_LIST); //await
store.dispatch(ActionNames.GET_CITY_LIST); //await

Vue.prototype.getEnumsDisplay = function (category, key) {
  return this.$store.getters.getEnums.find((x) => x.Title == category).Enums.find((x) => x.Key == key).DisplayName;
};
Vue.config.productionTip = false;

Vue.prototype.jQuery = jQuery;
// HS 20/05/2023 !!! (key) --> (key,def) eğer key yoksa varsayılan değeri göstermesi için yeni parametre eklendi
Vue.prototype.$localization = (key, def = null) => {
  /*if (store.getters.getLocalisationKeys.find((k) => k.LocalizationKey == key).LocalizationValue != null) {
    return store.getters.getLocalisationKeys.find((k) => k.LocalizationKey == key).LocalizationValue;
  } else {
    return def;
  }*/

  if (store.getters.getLocalisationKeys.length > 0) {
    //06/17/2023 HS !!! key null olduğu için LocalizationValue kontrol edilirken hata veriliyordu, ilk olarak key kontrol edildi
    if (store.getters.getLocalisationKeys.find((k) => k.LocalizationKey == key) != null) {
      return store.getters.getLocalisationKeys.find((k) => k.LocalizationKey == key).LocalizationValue;
    } else {
      return def;
    }
  }
  return def;
};
Vue.prototype.fileSize = function (bytes) {
  bytes = bytes * 1024 * 1024;
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};
Vue.prototype.$errorMessage = (key, ValidationErrors) => {
  if (key.length === 0) return null;
  key = key.toLowerCase();
  if (ValidationErrors.some((k) => k.Key.toLowerCase() == key)) {
    return ValidationErrors.find((k) => k.Key.toLowerCase() == key).Value;
  } else {
    return null;
  }
};

Vue.prototype.getExtension = function (extension) {
  let url = null;
  if (extension == ".pdf") {
    url = "/img/file/PDF.svg";
  } else if (extension == ".docx" || extension == ".doc") {
    url = "/img/file/DOC.svg";
  } else if (extension == ".xlsx" || extension == ".xls") {
    url = "/img/file/XLS.svg";
  } else if (extension == ".zip" || extension == ".rar") {
    url = "/img/file/ZIP.svg";
  } else {
    url = "/img/file/Unknown.svg";
  }
  return url;
};
const io = require("socket.io-client");
Vue.prototype.$io = io;
Vue.prototype.$socket = null;
import { client } from "./helpers/axiosHelpers";
Vue.prototype.downloadFiles = async function (key) {
  var res = await client.post("/Account/GeneratePreSignedUrl", { ObjectKey: key });
  if (res.data.HasError) {
    this.$message.error(res.data.Message);
  } else {
    window.open(res.data.Data.Url, "_blank");
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    // e0b1fc57-c3c2-47ed-9e12-9201f0e9b62a  orjinal key !!! HS 28/03/2023
    this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL, serviceWorkerParam: { scope: "/push/onesignal/" }, serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js", serviceWorkerUpdaterPath: "push/onesignal/OneSignalSDKUpdaterWorker.js" });
  },
}).$mount("#app");
