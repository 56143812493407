export default {
  setUserAppointments(state, data) {
    state.appointments = data;
  },
  setPendingAppointments(state, data) {
    state.pendingAppointments = data;
  },
  deletePendingAppointments(state, data) {
    state.pendingAppointments = state.pendingAppointments.filter((item) => item.id !== data.id);
  },
  setCurrentAppointment(state, data) {
    state.currentAppointment = data;
  },
  setCurrentAppoinmentMessage(state, data) {
    state.currentMessage = data.reverse();
  },
  addCurrentAppointmentMessage(state, data) {
    state.currentMessage.push(data);
  },
  setCurrentDoctor(state, data) {
    state.currentDoctor = data;
  },
  clearMessage(state) {
    state.currentMessage = [];
  },
  setAppointmentPrice(state, data) {
    state.appointmentPrice = data;
  },
  setDiscountAppointmentPrice(state, data) {
    state.discountAppointmentPrice = data;
  },
  setIsFree(state, data) {
    state.isFree = data;
  },
  setTaxRate(state, data) {
    state.taxRate = data;
  },
  setSmsShowDialog(state, data) {
    state.SmsShowDialog = data;
  },
  setMobilePaymentEnabled(state, data) {
    state.isMobilePaymentEnabled = data;
  },
};
