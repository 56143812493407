<template>
  <div id="skrs-listesi">
    <div class="title">SKRS Listesi</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>SKRS İşlemleri</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-tooltip class="item" effect="dark" content="Export" placement="top">
          <el-button class="import" @click="exportFile()"><i class="icon-export"></i></el-button>
        </el-tooltip>
      </div>
    </div>

    <div class="table-general">
      <div class="search-status">
        <div class="status">
          <div class="show">Göster</div>
          <el-select v-model="filter.PageSize" placeholder="Seçiniz...">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>


        </div>

        <div class="search">
          <el-input placeholder="Kayıt Ara [Enter]" v-model="filter.SearchTerm" @change="applayFilter()" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>

      <div class="table">
        <el-table :data="SkrsListPage" style="width: 100%" v-on:sort-change="sortHandler">
          <el-table-column label="SKRS ADI" width="380">
            <template slot-scope="scope"> {{ scope.row.adi }} </template>
          </el-table-column>

          <el-table-column label="SKRS KODU" width="200">
            <template slot-scope="scope"> {{ scope.row.kodu }} </template>
          </el-table-column>

          <el-table-column label="AÇIKLAMA" width="250">
            <template slot-scope="scope"> {{ scope.row.aciklama }} </template>
          </el-table-column>

          <el-table-column label="KOD SAYISI" width="120">
            <template slot-scope="scope"> {{ scope.row.ItemsCount }} </template>
          </el-table-column>

          <el-table-column label="" width="170" align="center">
            <template slot-scope="scope">
              <!-- disabled = true ile hint gösterilmemesi sağlandı -->
              <el-tooltip class="item" effect="dark" content="" placement="right" disabled="true">
                <el-button class="primary-btn" type="success" round @click="skrsSaveQuery(scope)"
                  :loading=loading>Kaydet</el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="" width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="" placement="right" disabled="true">
                <el-button type="danger" :disabled="scope.row.ItemsCount == -1 || loading" round
                  @click="skrsDeleteQuery(scope)">Sil</el-button>
              </el-tooltip>

            </template>
          </el-table-column>

          <el-table-column label="" width="170" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="SKRS Kodlarını Görüntüle" placement="right" disabled="true">
                <el-button type="primary" :disabled="scope.row.ItemsCount < 1 || loading" round
                  @click="skrsItemsView(scope)">Görüntüle</el-button>
              </el-tooltip>

            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
              <div class="no-data-text">Herhangi bir SKRS kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <div class="pagination">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page >
          SkrsFilteredList?.TotalCount ? SkrsFilteredList?.TotalCount : filter.PageSize * filter.Page }} {{
    $localization('web-pagination-info-text') }} {{ SkrsFilteredList?.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next"
          :total="SkrsFilteredList?.TotalCount"></el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="skrsCanViewItems" custom-class="skrs-items-view-dialog" :with-header="false" top="1vh"
      :close-on-click-modal="false" :modal-append-to-body="false" :show-close="false" width="70%">
      <div class="close" @click="skrsCanViewItems = false">
        <i class="icon-close"></i>
      </div>
      <el-form ref="form">
        <div class="head-tag new-doc">{{ SkrsItemsList?.skrsCode.UssItem.adi }}</div>
        <div class="all-parts">

          <!--  Tablo bası-------------------------------------------------------------------------------------------------->

          <div class="table-general">
            <div class="search-status">
              <div class="status">

                <div class="show">Göster</div>
                <el-select v-model="filteritems.PageSize" placeholder="Seçiniz...">
                  <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
                </el-select>

                <div class="show">Sıralama</div>
                <el-select v-model="filteritems.SortBy" placeholder="Seçiniz...">
                  <el-option v-for="(item, index) in sortfield" :key="index" :label="item" :value="item"> </el-option>
                </el-select>
              </div>

              <div class="search">
                <el-input placeholder="Kayıt Ara [Enter]" v-model="filteritems.SearchTerm" @change="getDataItems()"
                  clearable>
                  <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
                </el-input>
              </div>


            </div>

            <div class="table">
              <el-table :data="SkrsItemsList?.SkrsItemList" style="width: 100%" v-on:sort-change="sortHandlerItems">
                <el-table-column label="ADI" width="500">
                  <template slot-scope="scope"> {{ scope.row.SkrsAlanAdi }} </template>
                </el-table-column>

                <el-table-column label="KODU">
                  <template slot-scope="scope"> {{ scope.row.SkrsAlanKodu }} </template>
                </el-table-column>

                <el-table-column label="MERNİS KODU">
                  <template slot-scope="scope"> {{ scope.row.SkrsMernisKodu }} </template>
                </el-table-column>

                <el-table-column label="İL KODU">
                  <template slot-scope="scope"> {{ scope.row.SkrsIlKodu }} </template>
                </el-table-column>


                <template slot="empty">
                  <div class="lottie-container">
                    <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                    <div class="no-data-text">Kayıt bulunamadı.</div>
                    <div class="no-data-text">Herhangi bir SKRS kaydı bulunamamıştır.</div>
                  </div>
                </template>
              </el-table>
            </div>

            <div class="pagination">
              <div class="info">{{ filteritems.PageSize * filteritems.Page - filteritems.PageSize }}-{{
                filteritems.PageSize
                *
                filteritems.Page >
                SkrsItemsList?.TotalCount ? SkrsItemsList?.TotalCount : filteritems.PageSize * filteritems.Page }} {{
    $localization('web-pagination-info-text') }} {{ SkrsItemsList?.TotalCount }}</div>
              <el-pagination background :current-page.sync="filteritems.Page" :page-size="filteritems.PageSize"
                layout="prev, pager, next" :total="SkrsItemsList?.TotalCount"></el-pagination>
            </div>

          </div>

          <!-- Tablo sonu ---------------------------------------------------------------------->
        </div>
        <div class="button-group-admin">
          <el-button class="cancelReportAdmin" @click="skrsCanViewItems = false">Kapat</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
//import { nextTick } from 'vue'
export default {
  name: "Home",
  computed: {
    getEnums() {
      return this.$store.state.auth.enums;
    },
    paginatedItems() {
      let page = 1;
      return [].concat.apply(
        [],
        this.SkrsFilteredList?.getSkrsCodesUssResult?.sonuc.map((item, index) =>
          index % this.filter.PageSize ?
            [] :
            { page: page++, items: this.SkrsFilteredList?.getSkrsCodesUssResult?.sonuc.slice(index, index + this.filter.PageSize) }
        )
      );
    },
    SkrsListPage() {
      if (this.SkrsFilteredList?.getSkrsCodesUssResult?.sonuc) {
        let currentPageItems = this.paginatedItems.find(pages => pages.page == this.filter.Page);
        return currentPageItems ? currentPageItems.items : [];
      }
      return null
    }
  },
  data() {
    return {
      filter: {
        baslangicTarihi: null,
        ReturnOnlyIfExistsInData: false,
        PageSize: 10,
      },
      filterdelete: {
        SkrsKodu: "",

      },
      filteritems: {
        SkrsKodu: "",
        PageSize: 10,
        Page: 1,
        SearchTerm: "",
        SortBy: "Adı",
        ReturnIfInActive: true,
      },
      sortfield: ["Kodu", "Adı", "Mernis Kodu", "İl Kodu"],
      /*
      "SkrsAlanKodu" => p => p.Ascending(x => x.SkrsAlanKodu),
                            "SkrsAlanAdi" => p => p.Ascending(x => x.SkrsAlanAdi),
                            "SkrsMernisKodu" => p => p.Ascending(x => x.SkrsMernisKodu),
                            "SkrsIlKodu" =
      */
      SkrsItemsList: null,
      SkrsList: null,//{ getSkrsCodesUssResult: { sonuc: [] } },
      SkrsFilteredList: null,
      result: {
        Appointments: [],
        TotalCount: 0,
        PageCount: 0,
      },
      pagesize: [10, 20, 30, 40],
      loading: false,
      skrsCanViewItems: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {

    async sortHandler(params) {

      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    async sortHandlerItems(params) {

      this.filteritems.SortBy = params.prop;
    },
    exportFile() {
      var label = "GecmisGorusmeler.xlsx";
      this.$client
        .get("/Appointment/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    goDetails(item) {

      this.$router.push("/admin/gecmis-gorusmeler-detay/" + item.ID);
    },
    async getData() {
      var res = await this.$client.post("/SkrsCode/List", this.filter);


      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.SkrsList = res.data.Data;
        //this.SkrsFilteredList = this.SkrsList;
        //original listeyi clone ediyoruz cunku yukarıdaki atama sadece adresleri eşitliyor. Başka bir nesne yaratmıyor
        this.SkrsFilteredList = JSON.parse(JSON.stringify(this.SkrsList));

      }
    },

    async skrsSaveQuery(item) {
      if (item.row.ItemsCount > 0) { // kayıt varsa sor
        this.$confirm('SKRS kaydı önceden var. Güncellensin mi?', 'Dikkat', {
          confirmButtonText: 'Güncelle',
          cancelButtonText: 'İptal',
          type: 'warning'
        }).then(() => {

          this.skrsSave(item);

        }).catch(() => {

        });
      }
      else {
        this.skrsSave(item);

      }
    },

    async skrsSave(item) {

      //test için 1
      //item.row.ItemsCount = 5;
      //console.log("Bu item : ");
      //console.log(item);

      // test 2
      //this.loading = true;
      //setTimeout(() => { this.loading = false; }, 1000);


      this.loading = true;


      var res = await this.$client.post("/SkrsCode/AddOrUpdate", item.row);


      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        //this.SkrsList = res.data.Data;
        item.row.ItemsCount = res.data.Data.ItemsCount;

        //console.log(this.SkrsList);
        this.$message.success(res.data.Message)
      }

      this.loading = false;

    },

    async skrsDeleteQuery(item) {

      this.$confirm('SKRS kaydı ve kodları silinecektir. Devam edilsin mi?', 'Dikkat', {
        confirmButtonText: 'Sil',
        cancelButtonText: 'İptal',
        type: 'warning'
      }).then(() => {

        this.skrsDelete(item);

      }).catch(() => {

      });
    },

    async skrsDelete(item) {


      this.loading = true;

      //console.log("Skrs KOdu = " + item.row.kodu);
      this.filterdelete.SkrsKodu = item.row.kodu;
      //console.log(this.filterdelete);
      var res = await this.$client.post("/SkrsCode/Delete", this.filterdelete);



      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        //this.SkrsList = res.data.Data;
        item.row.ItemsCount = -1;

        //console.log(this.SkrsList);
        this.$message.success(res.data.Message)
      }

      this.loading = false;


    },

    async getDataItems() {
      var res = await this.$client.post("/SkrsCode/ListItems", this.filteritems);


      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.SkrsItemsList = null;
      } else {
        this.SkrsItemsList = res.data.Data;
        //this.$message.success(res.data.Message);

      }
    },

    async skrsItemsView(item) {

      //this.loading = true;

      //this.$router.push("/admin/skrsgoruntuleme/" + item.row.kodu + "/" + item.row.adi);
      this.filteritems.SkrsKodu = item.row.kodu;
      this.filteritems.Page = 1;
      this.filteritems.SearchTerm = "";
      //console.log(this.filteritems);

      //await yapmazsak SkrsItemsList güncellenmiyor
      await this.getDataItems();

      if (this.SkrsItemsList != null) {
        //console.log(this.SkrsItemsList)
        this.skrsCanViewItems = true;

      }
    },


    async applayFilter() {

      this.loading = true;
      //console.log("SearchTerm = " + this.filter.SearchTerm);

      let query = this.filter.SearchTerm.toLowerCase();
      //console.log("Sorgu = " + query);

      let tmpsonuc = this.SkrsList?.getSkrsCodesUssResult?.sonuc;

      if (tmpsonuc != null) {
        //console.log("tmpsonuc : ");
        //console.log(tmpsonuc);

        //let len = tmpsonuc.filter(item => item.adi.toLowerCase().indexOf(query) >= 0).length;
        this.SkrsFilteredList.getSkrsCodesUssResult.sonuc = tmpsonuc.filter(item => item.adi.toLowerCase().indexOf(query) >= 0);
        this.SkrsFilteredList.TotalCount = this.SkrsFilteredList.getSkrsCodesUssResult.sonuc.length;
        //console.log("SkrsFilteredList : ");
        //console.log(this.SkrsFilteredList.getSkrsCodesUssResult.sonuc);

        //console.log("SkrsList : ");
        //console.log(this.SkrsList.getSkrsCodesUssResult.sonuc);

      }
      this.loading = false;

    },
  },
  watch: {
    "filteritems.PageSize": function () {
      if (this.skrsCanViewItems) {
        this.getDataItems();
      }
    },
    "filteritems.Page": function () {
      if (this.skrsCanViewItems) {
        this.getDataItems();
      }
    },
    "filteritems.SortBy": function () {
      if (this.skrsCanViewItems) {
        this.filteritems.SearchTerm = "";
        this.getDataItems();
      }
    },


    /*
    "filter.SearchTerm": function () {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        // this.getData();
        console.log(this.SkrsList);
        this.SkrsFilteredList = this.SkrsList;
        this.SkrsFilteredList.getSkrsCodesUssResult.sonuc = this.SkrsList.getSkrsCodesUssResult.sonuc[0];
        this.SkrsFilteredList.PageCount = 1;
        this.SkrsFilteredList.TotalCount = 1;
      }, 400);
    },
    */

  },

};
</script>

<style lang="less">
#skrs-listesi {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .table-general {
    display: flex;
    flex-direction: column;

    .el-table {
      .el-table__header-wrapper {
        .el-table__header {
          padding-left: 24px;
        }
      }

      .el-table__body-wrapper {
        .el-table__body {
          .el-table__row {
            .el-table__cell {
              padding-left: 24px;
            }
          }
        }
      }
    }

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .show {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
          margin-right: 10px;
        }

        .el-select {
          border-radius: 8px;
          border: none;

          .el-input {
            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #234A98;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.orange {
        background-color: #ff8a48;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }
}

.skrs-items-view-dialog {
  width: 50%;
  border-radius: 14px !important;

  .el-dialog__header {
    padding: 0;
  }

  .close {
    position: absolute;
    right: 1rem;
    cursor: pointer !important;
    top: 1rem;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
    background-color: #eaedf0;

    .icon-close {
      width: 100%;
      height: 100%;
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .el-form {
    padding: 20px;

    .all-parts {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 80vh;
      overflow-y: scroll;
      padding-right: 30px;
    }

    .head-tag {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
    }

    .mid-tag {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #44566c;
    }

    .el-select,
    .el-input {

      height: 38px;
    }

    .part {
      &>.input-container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .phone {
          padding: 2px;

          .vue-phone-number-input {
            width: 100%;
            display: flex;
            gap: 10px;
          }

          .flex-1 {
            width: 100%;
          }

          display: flex;
          flex-direction: row;
          gap: 10px;

          .vue-country-select {
            border: none;
            background-color: #f8fafb;
            border-radius: 4px;

            .dropdown.open {
              .dropdown-list {
                border: none;

                .dropdown-item {
                  height: 40px;
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  &.highlighted {
                    background-color: #f8fafb;
                  }

                  &.last-preferred {
                    border-bottom: 1px solid #234A98;
                  }
                }

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                  border: 4px solid rgba(0, 0, 0, 0.2);
                  background-clip: padding-box;
                  border-radius: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: white;
                }
              }
            }
          }


        }
      }

      .image {
        cursor: pointer;
        width: 170px;
        background-position: center;
        background-size: cover !important;
        height: 170px;
        background: #44566c;
        opacity: 0.6;
        border-radius: 40px;
        box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
        display: flex;
        flex-direction: column;
        justify-content: center;

        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }

      .text {
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        color: #8697a8;
        margin-bottom: 10px;

        .red {
          color: #ff3d57;
        }
      }

      .el-input__inner {
        padding: 12px 19px;
      }

      input,
      textarea {
        background-color: #f8fafb;
        font-size: 15px;
        font-weight: 400;
        color: #44566c;
        border: none;

        &:focus {
          border-color: inherit;
        }
      }

      .el-textarea {
        textarea {
          height: 150px;
          padding: 16px 18px;
        }
      }
    }

    .button-group-admin {
      padding-top: 10px;

      .confirmReportAdmin {
        display: flex;
        align-items: center;
      }
    }
  }

  .upload-folder {
    .part {
      margin-bottom: 35px;
    }

    .upload-area {
      display: flex;
      justify-content: center;

      .upload-demo {
        .el-upload {
          .el-upload-dragger {
            background-color: #eaedf0;
            width: 401px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            i {
              margin: 0;
              font-size: 30px;
              line-height: 0;
            }

            .el-upload__text {
              font-size: 15px;
              color: #44566c;
              font-weight: 400;

              em {
                color: #234A98;
              }
            }
          }
        }

        .el-upload-list {
          background-color: #f8fafb;
        }
      }
    }

    .uploading-all-documents {
      max-height: 257px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .document {
        width: 420px;
        height: 75px;
        background-color: #f8fafb;
        margin: 8px 0;
      }

      .still-uplaoading {
        display: flex;
        gap: 26px;
        padding: 18px 16px 16px 16px;

        .file-type {
          i {
            width: 31px;
            height: 40px;
          }
        }

        .right {
          width: 100%;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .upload-info {
              color: #44566c;
              font-size: 13px;
              font-weight: 400;
            }

            .cancel-button {
              background-color: #44566c;
              height: 14px;
              width: 14px;
              border-radius: 50%;
              padding: 12px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              i {
                width: 8px;
                height: 8px;
                display: block;
                mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                -webkit-mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                background-color: white;
              }
            }
          }
        }
      }

      .uploaded {
        display: flex;
        gap: 26px;
        padding: 18px 16px 16px 16px;

        .file-type {
          i {
            width: 31px;
            height: 40px;
          }
        }

        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .texts {
            .document-name {
              color: #44566c;
              font-size: 13px;
              font-weight: 400;
            }

            .document-size {
              color: #8697a8;
              font-size: 13px;
              font-weight: 400;
              margin-top: 4px;
            }
          }

          .delete {
            button {
              border: none;
              background-color: inherit;
              padding: 0;

              i {
                font-size: 22px;
                color: #8697a8;
              }
            }
          }
        }
      }
    }
  }
}
</style>
