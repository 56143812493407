import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import store from "@/store/index";
import Layout from "../components/ClientLayout.vue";
import Layout2 from "../components/DoctorLayout.vue";
import Layout3 from "../components/AdminLayout.vue";
import Layout4 from "../components/NurseLayout.vue";
import Home from "@/views/client/Home.vue";
import Home2 from "@/views/doctor/Home.vue";
import Home3 from "@/views/nurse/Home.vue";
import GorusmeDetay from "@/views/client/Details.vue";
import Interview from "@/views/client/Interview.vue";
import Room from "@/views/client/Room.vue";
import DoctorRoom from "@/views/doctor/Room.vue";
import NurseRoom from "@/views/nurse/Room.vue";
import Profile from "@/views/client/Profile.vue";
import Faq from "@/views/client/Faq.vue";
import Prescriptions from "@/views/client/Prescriptions.vue";
import { client } from "../helpers/axiosHelpers";
import SkrsSaveUpdateDelete from "@/views/admin/SksrsAddUpdateDelete.vue";
import Institution from "@/views/admin/Institution.vue";
import Product from "@/views/admin/Product.vue";

Vue.use(VueRouter);

function generalControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1) {
    next({ path: "/kullanici/gorusme-baslat" });
  } else if (user.Role == 2) {
    next({ path: "/doktor" });
  } else if (user.Role == 10 || user.Role == 12) {
    next({ path: "/admin" });
  } else if (user.Role == 3) {
    next({ path: "/hemsire" });
  }
}

function doctorControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1) {
    next({ path: "/kullanici" });
  } else if (user.Role == 2) {
    next();
  }
}
function superAdminControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1) {
    next({ path: "/kullanici" });
  } else if (user.Role == 12) {
    next();
  }
}

function nurseControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1) {
    next({ path: "/kullanici" });
  } else if (user.Role == 3) {
    next();
  }
}

function adminControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 10 || user.Role == 12) {
    next();
  } else if (user.Role == 2) {
    next("/doktor");
  } else if (user.Role == 1) {
    next("/kullanici");
  } else if (user.Role == 3) {
    next("/hemsire");
  }
}

function userControl(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1) {
    next();
  } else if (user.Role == 2) {
    next({ path: "/doktor" });
  } else if (user.Role == 3) {
    next({ path: "/hemsire" });
  }
}

const routes = [
  {
    path: "/giris",
    name: "Login",
    component: Login,
  },
  {
    path: "/kayit-ol/:registertype?",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Register.vue"),
  },
  // {
  //     path: '/',
  //     name: 'Home',
  //     component: () =>
  //         import ( /* webpackChunkName: "about" */ '@/components/Layout.vue')
  // },
  {
    path: "/",
    name: "Home",
    beforeEnter: generalControl,
  },
  {
    path: "/kullanici",
    component: Layout,
    beforeEnter: userControl,
    children: [
      {
        path: "/",
        name: "User",
        meta: { title: "Home" },
        component: Home,
      },
      {
        path: "gorusme-detay/:id",
        name: "GorusmeDetay",
        meta: { title: "Görüşme Detay" },
        component: GorusmeDetay,
      },
      {
        path: "gorusme-baslat",
        name: "GorusmeBaslat",
        meta: { title: " Görüşme Başlat " },
        component: Interview,
      },
      {
        path: "gorusme-baslat/:id",
        name: "GorusmeBaslat",
        meta: { title: " Görüşme Başlat " },
        component: Interview,
      },
      {
        path: "gorusme-baslat/:expired",
        name: "GorusmeBaslat",
        meta: { title: " Görüşme Başlat " },
        component: Interview,
      },
      {
        path: "gorusme-odasi",
        name: "GorusmeOdasi",
        meta: { title: " Görüşme Odası " },
        component: Room,
      },
      {
        path: "gorusme-odasi/:id",
        name: "GorusmeOdasi",
        meta: { title: " Görüşme Odası " },
        component: Room,
      },
      {
        path: "gorusme-odasi/:expired",
        name: "GorusmeOdasi",
        meta: { title: " Görüşme Odası " },
        component: Room,
      },
      {
        path: "profilim",
        name: "Profilim",
        meta: { title: " Profilim " },
        component: Profile,
      },
      {
        path: "recetelerim",
        name: "Recetelerim",
        meta: { title: " Reçetelerim " },
        component: Prescriptions,
      },
      {
        path: "sikca-sorulan-sorular",
        name: "SSS",
        meta: { title: " Sıkça Sorulan Sorular " },
        component: Faq,
      },
    ],
  },
  {
    path: "/doktor",
    component: Layout2,
    beforeEnter: doctorControl,
    children: [
      {
        path: "/",
        name: "DoktorHome",
        meta: { title: "Home" },
        component: Home2,
      },
      // {
      //   path: "calendar",
      //   meta: { title: "Calendar" },
      //   component: () => import("@/views/doctor/Calendar.vue"),
      // },
      {
        path: "profilim",
        name: "DoktorProfil",
        meta: { title: "Profile" },
        component: () => import("@/views/doctor/Profile.vue"),
      },
      {
        path: "mesajlar",
        name: "DoktorMesajlar",
        meta: { title: "Messages" },
        component: () => import("@/views/doctor/Messages.vue"),
      },
      {
        path: "gorusme-detay/:id",
        name: "DoktorGorusmeDetay",
        meta: { title: "Doktor Görüşme Detay" },
        component: GorusmeDetay,
      },
      {
        path: "dosyalar",
        name: "DoktorDosyalar",
        meta: { title: "Dosyalar" },
        component: () => import("@/views/doctor/FileManager.vue"),
      },
      {
        path: "gorusme-odasi/:id",
        name: "DoktorGorusmeOdasi",
        meta: { title: " Görüşme Odası " },
        component: DoctorRoom,
      },
    ],
  },

  {
    path: "/admin",
    component: Layout3,
    beforeEnter: adminControl,
    children: [
      {
        path: "/",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Home.vue"),
      },
      {
        path: "kullanicilar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Users.vue"),
      },
      {
        path: "kullanici-detay/:id",
        meta: { title: "Home" },
        component: () => import("@/views/admin/UserDetail.vue"),
      },
      {
        path: "doktorlar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Doctor.vue"),
      },
      {
        path: "hemsireler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Nurse.vue"),
      },
      {
        path: "branslar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Specialities.vue"),
      },
      {
        path: "doktor-detay/:id",
        meta: { title: "Home" },
        component: () => import("@/views/admin/DoctorDeails.vue"),
      },
      {
        path: "dosyalar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/FileManager.vue"),
      },
      {
        path: "ayarlar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Settings.vue"),
      },
      {
        path: "gecmis-gorusmeler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/PastAppointment.vue"),
      },
      {
        path: "odeme-gecmisi",
        meta: { title: "Home" },
        component: () => import("@/views/admin/PaymentHistory.vue"),
      },
      {
        path: "islem-gecmisi",
        meta: { title: "Home" },
        component: () => import("@/views/admin/TransactionHistory.vue"),
      },
      // {
      //   path: "kayit-indirme-gecmisi",
      //   meta: { title: "Home" },
      //   component: () => import("@/views/admin/RecordDownloadHistory.vue"),
      // },
      {
        path: "doktor-not-gecmisi",
        meta: { title: "Home" },
        component: () => import("@/views/admin/ApprovalHistory.vue"),
      },
      // {
      //   path: "gorusme-kayit-onay",
      //   meta: { title: "Home" },
      //   component: () => import("@/views/admin/InterviewRecordConfirmation.vue"),
      // },
      {
        path: "gecmis-gorusmeler-detay/:id",
        meta: { title: "Home" },
        component: () => import("@/views/admin/PastAppointmentDetails.vue"),
      },
      {
        path: "kategoriler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Categories.vue"),
      },
      {
        path: "makaleler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Posts.vue"),
      },
      {
        path: "mesajlar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Messages.vue"),
      },
      {
        path: "destek-talebi",
        meta: { title: "Home" },
        component: () => import("@/views/admin/SupportRequest.vue"),
      },
      {
        path: "segmentasyon",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Segmentation.vue"),
      },
      {
        path: "sistem-dilleri",
        meta: { title: "Home" },
        component: () => import("@/views/admin/SystemLanguage.vue"),
      },
      {
        path: "yerellestirme",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Localization.vue"),
      },
      {
        path: "faq",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Faq.vue"),
      },
      {
        path: "sozlesmeler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Policy.vue"),
      },
      // {
      //   path: "slider",
      //   meta: { title: "Home" },
      //   component: () => import("@/views/admin/Slider.vue"),
      // },
      {
        path: "sabitalanlar",
        meta: { title: "Home" },
        component: () => import("@/views/admin/FixedFields.vue"),
      },
      {
        path: "skrskaydi",
        meta: { title: "Home" },
        component: SkrsSaveUpdateDelete,
      },
      {
        path: "kurumlar",
        meta: { title: "Home" },
        component: Institution,
      },
      {
        path: "hizmetler",
        meta: { title: "Home" },
        component: Product,
      },
      {
        path: "web-dosyalari",
        meta: { title: "Home" },
        component: () => import("@/views/admin/WebFiles.vue"),
      },
      // {
      //   path: "dilsecenekleri",
      //   meta: { title: "Home" },
      //   component: () => import("@/views/admin/LanguageOptions.vue"),
      // },

      {
        beforeEnter: superAdminControl,
        path: "adminler",
        meta: { title: "Home" },
        component: () => import("@/views/admin/Admin.vue"),
      },
    ],
  },

  {
    path: "/hemsire",
    component: Layout4,
    beforeEnter: nurseControl,
    children: [
      {
        path: "/",
        name: "HemsireHome",
        meta: { title: "Home" },
        component: Home3,
      },

      {
        path: "profilim",
        name: "HemsireProfil",
        meta: { title: "Profile" },
        component: () => import("@/views/nurse/Profile.vue"),
      },
      {
        path: "mesajlar",
        name: "HemsireMesajlar",
        meta: { title: "Messages" },
        component: () => import("@/views/nurse/Messages.vue"),
      },
      {
        path: "gorusme-detay/:id",
        name: "HemsireGorusmeDetay",
        meta: { title: "Hemsire Görüşme Detay" },
        component: GorusmeDetay,
      },
      {
        path: "dosyalar",
        name: "HemsireDosyalar",
        meta: { title: "Dosyalar" },
        component: () => import("@/views/nurse/FileManager.vue"),
      },
      {
        path: "gorusme-odasi/:id",
        name: "HemsireGorusmeOdasi",
        meta: { title: " Görüşme Odası " },
        component: NurseRoom,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  var token = localStorage.getItem("token");
  await store.dispatch("getEnums");
  if (token != null) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await store.dispatch("getUser");

    if (to.name !== "Login" && to.name !== "Register") {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    if (to.name == "Login" || to.name == "Register") {
      next();
    } else {
      next({ name: "Login" });
    }
  }
});

export default router;
