//#appointments
export const GET_APPOINTMENT_PRICE = "getAppointmentPrice";
export const GET_DISCOUNT_APPOINTMENT_PRICE = "getDiscountAppointmentPrice";
export const GET_IS_FREE = "getIsFree";
export const GET_TAX_RATE = "getTaxRate";
export const GET_MOBILE_PAYMENT_ENABLED = "getMobilePaymentEnabled";
//#appointments
//#definitions
export const GET_COUNTRY_LIST = "getCountryList";
export const GET_CITY_LIST = "getCityList";
export const GET_DISTRICT_LIST = "getDistrictList";
//#definitions
//#file-manager
export const GET_FILES = "getFiles";
export const DELETE_FILES = "deleteFiles";
export const GET_FILE_CATEGORIES = "getFileCategories";
export const DELETE_FILE_CATEGORY = "deleteFileCategory";
export const ADD_UPDATE_FILE_CATEGORY = "addUpdateFileCategory";
//#file-manager
