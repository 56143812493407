import { client } from "@/helpers/axiosHelpers";
import { Message, MessageBox } from "element-ui";

export default {

  async getPostList(context, payload) {
    var res = await client.post("/Blog/ListBlogPosts", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setPostList", res.data.Data.Items);
      context.commit("setPostPageCount", res.data.Data.PageCount);
      context.commit("setPostTotalCount", res.data.Data.TotalCount);
    }
  },
  async deleteOnePost(context, payload) {
    MessageBox.confirm("Bu makaleyi tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
      confirmButtonText: "Evet, Sil",
      cancelButtonText: "Vazgeç",
      customClass: "alert-dialog",
      confirmButtonClass: "primary-btn btn-shadow",
      cancelButtonClass: "secondarary-btn",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Blog/DeleteBlogPost", {
          Id: payload,
        });
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success("Makale başarıyla silindi.");
          await context.dispatch("getPostList", context.state.filter);
        }
      })
      .catch(() => { });
  },
  async deleteAllPost(context, payload) {
    MessageBox.confirm("Seçilenleri tamamen silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
      confirmButtonText: "Evet, Sil",
      cancelButtonText: "Vazgeç",
      customClass: "alert-dialog",
      confirmButtonClass: "primary-btn btn-shadow",
      cancelButtonClass: "secondarary-btn",
      type: "warning",
    })
      .then(async () => {
        await Promise.all(
          payload.map(async (item) => {
            var res = await client.post("/Blog/DeleteBlogPost", {
              Id: item.ID,
            });
            if (res.data.HasError) {
              Message.error(res.data.Message);
            }
          }),
        );
        await context.dispatch("getPostList", context.state.filter);
      })
      .catch(() => { });
  },
  async changeStatusPost(context, payload) {
    var res = await client.post("/Blog/ToggleIsActiveBlogPost",
      { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success("Başarıyla Güncellendi");
      await context.dispatch("getPostList", context.state.filter);

    }
  },
  async addOrUpdatePost(context, payload) {
    context.commit('setPostValidation', []);
    var res = await client.post("/Blog/AddUpdateBlogPost", payload);
    context.commit('setPostValidation', res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      if (payload.ID == null) {
        Message.success("Makale başarıyla eklendi.");
      } else {
        Message.success("Makale başarıyla güncellendi.");
      }
      await context.dispatch("getPostList", context.state.filter);
      this.form = {
        ID: null,
        Title: null,
        Image: null,
        Summary: null,
        Cover: null,
        IsActive: false,
        IsFeatured: false,
        Details: null,
        PublishedDate: null,
      };
      this.addUpdateDialog = false;
    }
  },
  async getCategoryList(context, payload) {
    var res = await client.post("/Blog/ListBlogCategories", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setCategoryList", res.data.Data.Items);
    }
  },
}