import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import defaultVariables from "@/default/variables";

const state = {
  filter: {
    PageSize: 10,
    SearchTerm: null,
    Status: null,
    Page: 1,
  },
  appointments: [],
  pendingAppointments: [],
  currentAppointment: {},
  currentMessage: [],
  currentDoctor: null,
  appointmentPrice: defaultVariables.appointmentPrice,
  discountAppointmentPrice: defaultVariables.appointmentPrice,
  isFree: defaultVariables.isFree,
  taxRate: defaultVariables.taxRate,
  SmsShowDialog: false, // Sms bekleme diallgu kapatmak için kullanılıyor,
  isMobilePaymentEnabled: false, // !!! FC 08/05/24++ True ise mobile ödeme aktif
};
export default {
  state,
  getters,
  actions,
  mutations,
};
