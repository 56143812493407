<template>
  <div id="conversations-detail" v-if="appointment">
    <portal to="new-header">
      <el-header class="new-header">
        <div class="title">{{ $localization('web-views-client-details-meet-info') }}</div>
        <div class="tab-menu">
          <el-menu class="el-menu-demo" default-active="1" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">
              <i class="icon-details"></i>
              <span> {{ $localization('web-views-client-details-details') }}</span>
            </el-menu-item>
            <el-menu-item index="2">
              <i class="icon-invoice"></i>
              <span>{{ $localization('web-views-client-details-price') }}</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="menu-icon-chat"></i>
              <span>{{ $localization('web-views-client-details-messages') }}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-header>
    </portal>
    <div class="title">
      {{ $localization('web-views-client-details-meet') }} <span>#{{ appointment.AppointmentNo }}</span>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>{{ $localization('web-views-client-details-my-meets') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-tooltip class="item" effect="dark" :content="$localization('web-views-client-details-dowload')" placement="top">
          <el-button v-if="activeName == 1" @click="downloadDetail">
            <i class="icon-download"></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <transition :key="activeName">
      <div v-if="activeName == 1 && appointment.PatientDiagnosis" class="detail">
        <div class="doctor">
          <div class="head">
            <div class="title">{{ $localization('web-views-client-details-doctor') }}</div>
            <div class="date" v-if="appointment.StartDate == null">{{ $localization('web-views-client-details-created-date') }} {{ $moment(appointment.CreatedDateTime).format("DD.MM.YYYY HH:mm") }}</div>
            <div class="date" v-else>{{ $localization('web-views-client-details-meet-date') }} {{ $moment(appointment.StartDate).format("DD.MM.YYYY HH:mm") }} - {{ $moment(appointment.EndDate).format("DD.MM.YYYY HH:mm") }}</div>
          </div>
          <div class="content" v-if="appointment.DoctorUser != null">
            <div class="doktor-nick"><i class="menu-icon-user"></i> <span>{{ $localization('web-views-client-details-name') }} </span>{{ appointment.DoctorUser.Title }}</div>
          </div>
          <div class="content" v-else>
            <p>{{ $localization('web-views-client-details-no-doctor-joined') }}</p>
          </div>
        </div>
        <div class="my-complaints">
          <div class="head">
            <div class="title">{{ $localization('web-views-client-details-my-complaints') }}</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis != null">
            <template v-if="appointment.PatientDiagnosis.Title != null || appointment.PatientDiagnosis.Details != null">
              <p v-html="appointment.PatientDiagnosis.Title"></p>
              <p v-html="appointment.PatientDiagnosis.Details"></p>
            </template>
            <p v-else>{{ $localization('web-views-client-details-no-complaint') }}</p>
          </div>
        </div>
        <div class="doctor-note" v-if="appointment.PatientDiagnosis">
          <div class="head">
            <div class="title">{{ $localization('web-views-client-details-doctor-notes') }}</div>
          </div>
          <div class="edit-note">
            <div>
              <div class="content doc-note" v-if="appointment.PatientDiagnosis.DoctorNotes != null">
                <p>{{ appointment.PatientDiagnosis.DoctorNotes }}</p>
              </div>
              <div class="content" v-else>
                <p>{{ $localization('web-views-client-details-no-notes-saved') }}</p>
              </div>
            </div>
            <i class="icon-edit-code" v-if="user.Role == 2" @click="noteDialogVisible = true"></i>
          </div>
        </div>

        <!--#region HS 20/05/2023 !!! E-reçete No eklendi -->
        <div class="prescriptions-no">
          <div class="head">
            <div class="title">{{ $localization('web-views-client-details-prescriptions-no', "E-Reçete No") }}</div>
          </div>
          <div class="edit-note">
            <div>
              <div class="content" v-if="appointment.PrescriptionsNo != null">
                <p>{{ appointment.PrescriptionsNo }}</p>
              </div>
              <div class="content" v-else>
                <p>{{ $localization('web-views-client-details-no-prescriptions-no-saved', "Doktor tarafından bir E-reçete kodu girilmedi.") }}</p>
              </div>
            </div>
            <i class="icon-edit-code" v-if="user.Role == 2" @click="PrescriptionsNoDialogVisible = true"></i>
          </div>
        </div>
        <!--#endregion -->


        <!-- <div class="diagnosis">
          <div class="head">
            <div class="title">Tanı Kodu</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.Diagnosises.length != 0">
            <p v-for="(item, index) in appointment.PatientDiagnosis.Diagnosises" :key="index">{{ item.Title }} - {{
              item.Description }}</p>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.DiagnosisCode == null">
            <p>Doktor tarafından bir tanı kodu belirtilmedi.</p>
          </div>
        </div>
        <div class="diagnosis">
          <div class="head">
            <div class="title">Tanı</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.DoctorDiagnosis != null">
            <p>{{ appointment.PatientDiagnosis.DoctorDiagnosis }}</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından bir tanı belirtilmedi.</p>
          </div>
        </div> -->
        <!-- <div class="diagnosis">
          <div class="head">
            <div class="title">Sevk Durumu</div>
          </div>
          <div class="content" v-if="appointment.PatientDiagnosis.TransferHospital != false">
            <p>{{ appointment.DoctorUser.Title }} tarafından hastaneye sevk edildi.</p>
          </div>
          <div class="content" v-else>
            <p>Doktor tarafından hastaneye sevk edilmedi.</p>
          </div>
        </div> -->

        <el-dialog :visible.sync="noteDialogVisible" width="30%" :close-on-click-modal="false" style="background-color: transparent">
          <div class="note">
            <div class="head">
              <div class="title" style="margin-bottom: 10px">{{ $localization('web-views-client-details-edit-note') }}
              </div>
            </div>
            <el-alert :title="$localization('web-views-client-details-warning')" type="warning" show-icon style="margin-bottom: 10px; width:100% !important">{{
              $localization('web-views-client-details-edit-note-warning-description') }}</el-alert>
            <div class="textarea">
              <el-input type="textarea" :rows="8" v-model="tempString"></el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <div class="button-group-admin">
              <el-button class="cancelReportAdmin" @click="noteDialogVisible = false; tempString = ''">{{
                $localization('web-views-client-details-cancel') }}</el-button>
              <el-button class="confirmReportAdmin" @click="updateDoctorNote"><i class="icon-send"></i>{{
                $localization('web-views-client-details-save') }}</el-button>
            </div>
          </span>
        </el-dialog>

        <!--#region HS 20/05/2023 !!! E-reçete dialog kısmı eklendi -->
        <el-dialog :visible.sync="PrescriptionsNoDialogVisible" width="30%" :close-on-click-modal="false" style="background-color: transparent">
          <div class="note">
            <div class="head">
              <div class="title" style="margin-bottom: 10px">{{ $localization('web-views-client-details-edit-note') }}
              </div>
            </div>
            <el-alert :title="$localization('web-views-client-details-warning')" type="warning" show-icon style="margin-bottom: 10px; width:100% !important">{{ $localization('web-views-client-details-edit-note-warning-description')
            }}</el-alert>
            <div class="textarea">
              <el-input type="text" :rows="1" v-model="tempString"></el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <div class="button-group-admin">
              <el-button class="cancelReportAdmin" @click="PrescriptionsNoDialogVisible = false; tempString = ''">{{
                $localization('web-views-client-details-cancel') }}</el-button>
              <el-button class="confirmReportAdmin" @click="updatePrescriptionsNo"><i class="icon-send"></i>{{
                $localization('web-views-client-details-save') }}</el-button>
            </div>
          </span>
        </el-dialog>
        <!--#endregion -->

      </div>
      <div v-if="activeName == 2" class="price">
        <div class="head">
          <div class="red-bar">
            {{ $localization('web-views-client-details-invoice') }}<br />
            <span>#{{ appointment.AppointmentNo }}</span>
          </div>
          <!--<div class="address-info">
            <div class="title">{{ $localization('web-views-client-details-elra-company-name') }}</div>
            <span>{{ $localization('web-views-client-details-elra-address') }}</span>
            <span>+90 216 123 45 78</span>
            <span>{{ $localization('web-views-client-details-elra-info-mail') }}</span>
            <span>{{ $localization('web-views-client-details-elra-domain') }}</span>
          </div>-->
          <div class="date-logo">
            <span>{{ $moment(appointment.ExpireDate).format("DD.MM.YYYY HH:ss") }}</span>
            <!--<img src="../../assets/img/logo.svg" alt="" />-->
          </div>
        </div>
        <div class="stick top">
          <div class="title">{{ $localization('web-views-client-details-service') }}</div>
          <div>{{ $localization('web-views-client-details-price') }}</div>
          <div>{{ $localization('web-views-client-details-amount') }}</div>
          <div>{{ $localization('web-views-client-details-total-price') }}</div>
        </div>
        <div class="stick">
          <div class="title">{{ $localization('web-views-client-details-online-health-consultation') }}</div>
          <div>{{ appointment.Price }} TL</div>
          <div>1</div>
          <div>{{ appointment.Price }} TL</div>
        </div>
        <div class="price-calc">
          <div class="subtotal">{{ $localization('web-views-client-details-subprice') }} <span>{{ ((appointmentPrice * 100) / (100 + appointmentTaxRate)).toFixed(2) }} TL</span></div>
          <div class="kdv">{{ $localization('web-views-client-details-tax') }} <span>{{ (appointmentPrice - ((appointmentPrice * 100) / (100 + appointmentTaxRate))).toFixed(2) }} TL</span></div>
          <div class="total">{{ $localization('web-views-client-details-total') }} <span>{{ appointment.Price }} TL</span></div>
        </div>
      </div>
      <div v-if="activeName == 3" class="meeting" id="meeting-container">
        <div class="meeting-info">
          <div class="date" v-if="appointment.EndDate != null">{{ $moment(appointment.EndDate).format("DD.MM.YYYY") }} {{ $localization('web-views-client-details-date-meet') }}</div>
          <div class="date" v-else>{{ $moment(appointment.CreatedDate).format("DD.MM.YYYY") }} {{ $localization('web-views-client-details-date-meet') }}</div>
          <!-- <div class="buttons">
            <i class="icon-star"></i>
            <i class="icon-info"></i>
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="icon-dots"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Action 1</el-dropdown-item>
                <el-dropdown-item>Action 2</el-dropdown-item>
                <el-dropdown-item>Action 3</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </div>
        <div class="meet-side" v-if="messages.length > 0">
          <div class="chat-box me" v-for="item in messages" :key="item.ID" :class="[{ me: user.ID == item.SenderUserId }, { doctor: user.ID != item.SenderUserId }]">
            <div class="top-bar">
              <div class="name">{{ item.SenderUser }}</div>
              <div class="date">{{ $moment(item.CreatedDateTime).format("HH:mm") }}</div>
            </div>
            <div class="dialog">
              <div class="attachment" v-if="item.Attachments.length > 0">
                <div class="title"><i class="icon-attachment"></i>{{ $localization('web-views-client-details-file') }}</div>
                <div class="box" style="cursor: pointer" v-for="file in item.Attachments" :key="file.ID" @click="downloadFiles(file.ObjectKey)">
                  <div class="document">
                    <i class="icon-document"></i>
                  </div>
                  <div class="info">
                    <div class="name">{{ file.FilenameReal }}</div>
                    <div class="kb">{{ fileSize(file.FileSizeInMb) }}</div>
                  </div>
                </div>
              </div>
              {{ item.MessageText }}
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <div class="text">{{ $localization('web-views-client-details-no-live-chat-in-this-meet') }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noteDialogVisible: false,
      // HS 20/05/2023 !!! ereçete için düzenleme diyalogları için eklendi
      PrescriptionsNoDialogVisible: false,

      activeName: 1,
      messages: [],
      appointment: {
        PatientDiagnosis: null,
        DoctorUser: null,
        AppointmentNo: null,
      },
      doctorUser: null,
      breadcrumb: this.$localization('web-views-client-details-detail'),
      breadItems: [this.$localization('web-views-client-details-detail'), this.$localization('web-views-client-details-price'), this.$localization('web-views-client-details-messages')],
      // HS 20/05/2023 !!! notlar ve ereçete için geçici string tutucu
      tempString: "",
    };
  },
  async beforeMount() {
    var payload = {
      AppointmentId: this.$route.params.id,
      Page: 1,
      PageSize: 100,
    };
    var res = await this.$client.post("/Appointment/ListMessages", payload);
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.messages = res.data.Data.Items.reverse();
    }

    this.$nextTick(() => {
      this.jQuery(".meet-side").animate({ scrollTop: this.jQuery(".meet-side").prop("scrollHeight") }, 250);
    });
  },
  async mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: 'Details | Elra Sağlık' });
    }

    var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id });
    if (res.data.HasError) {
      this.$message.error(res.data.Message);
    } else {
      this.appointment = res.data.Data.Appointment;
      this.doctorUser = res.data.Data.Doctor;
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    async updateDoctorNote() {
      //#region HS 20/05/2023 !!! değişken geçici değerden okundu
      this.appointment.PatientDiagnosis.DoctorNotes = this.tempString;
      this.tempString = ''
      //#endregion
      var res = await this.$client.post("/Doctor/UpdateDoctorNote", { AppointmentNo: this.appointment.AppointmentNo, DoctorNotes: this.appointment.PatientDiagnosis.DoctorNotes });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.close();
      }
    },
    async updatePrescriptionsNo() {
      //#region HS 20/05/2023 !!! değişken geçici değerden okundu
      this.appointment.PrescriptionsNo = this.tempString;
      this.tempString = ''
      //#endregion
      var res = await this.$client.post("/Doctor/UpdatePrescriptionsNo", { AppointmentNo: this.appointment.AppointmentNo, PrescriptionsNo: this.appointment.PrescriptionsNo });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.close();
      }
    },
    close() {
      this.noteDialogVisible = false;
      // HS 20/05/2023 !!! ereçete için düzenleme diyalogları için eklendi
      this.PrescriptionsNoDialogVisible = false;
    },
    handleSelect(key) {
      this.activeName = key;
    },
    downloadDetail() {
      var token = localStorage.getItem("token");
      //!!! HS 02.04.2023 globalyazilim için değiştirildi
      //window.open(`https://pdf.imageus.dev/?url=https://test-app.elra.health/pastappointment-details.html?id=${this.$route.params.id}token-seperator${token}`);
      window.open(`https://pdf.imageus.dev/?url=${process.env.VUE_APP_SITE_URL}pastappointment-details.html?id=${this.$route.params.id}token-seperator${token}`);
    },
  },
  watch: {
    activeName() {
      this.breadcrumb = this.breadItems[this.activeName - 1];
      if (this.activeName == 3) {
        this.$nextTick(() => {
          this.jQuery(".meet-side").animate({ scrollTop: this.jQuery(".meet-side").prop("scrollHeight") }, 250);
        });
      }
    },
  },
};
</script>
<style lang="less">
.el-menu-demo {
  border-radius: 1rem 1rem 0 0;

  :first-child {
    border-radius: 1rem 0 0 0;
  }

  :last-child {
    border-radius: 0 1rem 0 0;
  }
}

.el-dialog {
  border-radius: 10px !important;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__footer {
    .dialog-footer {
      .button-group-admin {
        .confirmReportAdmin {
          color: #fff;

        }

        .confirmReportAdmin:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
