<template>
  <div class="h-100">
    <el-dialog :visible.sync="writeReport" width="30%" class="writeReportClass" :close-on-click-modal="false">
      <div class="write-report">
        <h3>{{ $localization('web-client-room-my-complaint') }}</h3>
        <div class="input-container">
          <div class="title">{{ $localization('web-client-room-my-complaint-short-description') }}</div>
          <el-input class="primary-input" :placeholder="$localization('web-client-room-enter-your-complaint')" v-model="Appointment.PatientDiagnosis.Title"></el-input>
          <span class="error">{{ $errorMessage("Title", validation) }}</span>
        </div>
        <div class="input-container text-editor">
          <div class="title">{{ $localization('web-client-room-description') }}</div>
          <div class="text-edit">
            <div id="toolbar">
              <select class="ql-size">
                <option value="small"></option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-underline"></button>
              <button class="ql-italic"></button>
              <select class="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </div>
            <quill-editor v-model="Appointment.PatientDiagnosis.Details" ref="myQuillEditor" :options="editorOption"> </quill-editor>
          </div>
        </div>
        <ul class="file-list">
          <li class="list-item" v-for="(item, index) in Appointment.PatientDiagnosis.Attachments" :key="index">
            <div class="file">
              <img :src="getExtension(item.Extension)" />
            </div>
            <div class="details">
              <div class="title">{{ item.FilenameReal }}</div>
              <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
            </div>
            <div class="icon-download" @click="downloadFiles(item.ObjectKey)"></div>
          </li>
        </ul>

        <el-upload :action="$client.defaults.baseURL + 'Account/UploadFile?relatedItemId=' + $route.params.id + '&type=1'" :headers="{
          Authorization: $client.defaults.headers.common['Authorization'],
        }" :show-file-list="false" :on-success="fileSuccess">
          <el-button size="small" type="primary" class="primary-btn"><i class="icon-attachment"></i> {{ $localization('web-client-room-share-file') }}</el-button>
        </el-upload>
        <div class="button-group">
          <el-button class="secondarary-btn" @click="writeReport = false">{{ $localization('web-client-room-cancel') }}</el-button>
          <el-button class="primary-btn" @click="updateDiagnosis()"> <i class="icon-send"></i> {{ $localization('web-client-room-save') }}</el-button>
        </div>
      </div>
    </el-dialog>
    <WaitingRoom v-if="!meet" @openDialog="openDialog" />
    <MeetingRoom v-else />
  </div>
</template>

<script>
import WaitingRoom from "../../components/room/WaitingRoom.vue";
import MeetingRoom from "../../components/room/MeetingRoom.vue";
export default {
  components: { WaitingRoom, MeetingRoom },
  data() {
    return {
      appointmentInterval: null,
      meet: false,
      writeReport: false,
      Appointment: {
        PatientDiagnosis: {
          Title: "",
          Details: "",
          Attachments: [],
        },
      },
      validation: [],
      reportForm: {
        Title: "",
        Details: "",
        Attachments: [],
      },
      fileList: [],
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder: this.$localization('web-client-room-complaint-info'),
      },
    };
  },
  beforeCreate() {
    window.doNotThrowMe = true;
    this.$client.post("/Appointment/ShutdownOtherWindows", {});
    setTimeout(() => {
      window.doNotThrowMe = false;
    }, 1000);
  },
  async mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: 'Room | Elra Sağlık' });
    }

    this.$store.commit('setRoomChildren', null);
    var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id });
    if (res.data.HasError) {
      this.$message.error(res.data.ErrorMessage);
    } else {
      this.Appointment = res.data.Data.Appointment;
      if (this.Appointment.Status == 2) {
        this.meet = true;
      }
      if (this.Appointment.User.Kid != null) {
        var kid = this.Appointment.User.Kid
        this.$store.commit('setRoomChildren', kid.FirstName + ' ' + kid.LastName);
      }
    }

    this.appointmentInterval = setInterval(() => {
      this.getAppoinment();
    }, 5000);
  },
  beforeDestroy() {
    window.clearInterval(this.appointmentInterval);
  },
  methods: {
    async setCurrentAppointment() {
      //eslint-disable-line
      var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id }); //eslint-disable-line
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        setTimeout(async () => {
          this.$router.push("/");
        }, 2000);
      } else {
        this.$store.commit("setCurrentAppointment", res.data.Data.Appointment);
      }
    },
    async getAppoinment() {
      var res = await this.$client.post("/Appointment/Get", { AppointmentId: this.$route.params.id });
      if (res.data.HasError) {
        this.$message.error(res.data.ErrorMessage);
      } else {
        if (res.data.Data.Appointment.Status == 2) {
          this.meet = true;
          this.Appoinment = res.data.Data.Appointment;
          this.$store.commit("setCurrentDoctor", res.data.Data.Doctor);
        }
      }
    },
    fileSuccess(res) {
      this.Appointment.PatientDiagnosis.Attachments.push(res.Item);
    },
    async updateDiagnosis() {
      var payload = {
        AppointmentId: this.$route.params.id,
        Title: this.Appointment.PatientDiagnosis.Title,
        Details: this.Appointment.PatientDiagnosis.Details,
        Attachments: this.Appointment.PatientDiagnosis.Attachments,
      };
      var res = await this.$client.post("/Appointment/UpdatePatientDiagnosis", payload);
      this.validation = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(this.$localization('web-client-room-complaint-saved'));
        this.writeReport = false;

        this.setCurrentAppointment();
      }
    },
    openDialog() {
      this.validation = [];
      this.writeReport = true;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
  },
};
</script>

<style lang="less">
#toolbar {
  padding: 9px;
  border: none;
  background-color: #f8fafb !important;
  border-radius: 5px 5px 0px 0px;
  font-family: "Roboto" !important;
}

.ql-snow .ql-stroke {
  stroke: #8697a8;
}

.ql-active>.ql-snow .ql-stroke {
  stroke: #8697a8;
}

.ql-container {
  min-height: 100px;
  border: none !important;
  background-color: #f8fafb;
  resize: both;
}
</style>
