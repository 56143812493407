<template>
    <el-dialog id="verifyDialog" :visible.sync="show" :close-on-click-modal="false" :width="screenWidth > 991 ? '50%' : '90%'" custom-class="verifyDialog" :before-close="handleClose">
        <div class="title">{{ $localization("web-verify", "Doğrulama") }}</div>
        <div class="description">{{ isEmailValidation ? $localization("web-enter-mail-otp-code") : $localization("web-login-enter-otp-code") }}</div>
        <div class="otp-wrapper">
            <div class="otps" @keydown="handleKeydown">
                <el-input ref="otp1" placeholder="" class="otp" v-model="otp1" maxlength="1"></el-input>
                <el-input ref="otp2" placeholder="" class="otp" v-model="otp2" maxlength="1"></el-input>
                <el-input ref="otp3" placeholder="" class="otp" v-model="otp3" maxlength="1"></el-input>
                <el-input ref="otp4" placeholder="" class="otp" v-model="otp4" maxlength="1"></el-input>
                <el-input ref="otp5" placeholder="" class="otp" v-model="otp5" maxlength="1"></el-input>
                <el-input ref="otp6" placeholder="" class="otp" v-model="otp6" maxlength="1"></el-input>
            </div>
            <div class="timer">
                <vue-countdown-timer @end_callback="endCallback" :end-text="$localization('web-register-time-up')" :start-time="new Date().getTime()" :end-time="new Date().getTime() + (otpTimerSecond * 1000)" :interval="1000">
                    <template slot="countdown" slot-scope="scope">
                        <div class="otp-timer">
                            <span>{{ scope.props.minutes }}:</span>
                            <span>{{ scope.props.seconds }}</span>
                        </div>
                    </template>
                </vue-countdown-timer>
            </div>
        </div>
        <el-button class="save-button" @click="verifyOtp()" :loading="loading" :disabled="loading"><i class="icon-send" v-if="!loading"></i>{{ $localization('web-authentication-pages-verify-otp') }}</el-button>
    </el-dialog>
</template>

<script>
export default {
    props: {
        //value: Boolean, //v-model
        otpTimerSecond: {
            type: Number,
            required: true,
        },
        isEmailValidation: {
            type: Boolean,
            required: true,
        },
        tempUser: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.screenWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            this.screenWidth = window.innerWidth;
        });
    },
    data() {
        return {
            screenWidth: 0,
            show: true,
            otp1: null,
            otp2: null,
            otp3: null,
            otp4: null,
            otp5: null,
            otp6: null,
            loading: false,
            timer: true,
        };
    },
    methods: {
        endCallback: function () {
            if (this.timer) {
                this.timer = false;
                this.otp1 = null;
                this.otp2 = null;
                this.otp3 = null;
                this.otp4 = null;
                this.otp5 = null;
            }
        },
        handleKeydown(e) {
            if (e.key === "Backspace" || e.key === "Delete") {
                if (this.otp6 == null || this.otp6 == "") {
                    this.$refs.otp5.focus();
                }
                if (this.otp5 == null || this.otp5 == "") {
                    this.$refs.otp4.focus();
                }

                if (this.otp4 == null || this.otp4 == "") {
                    this.$refs.otp3.focus();
                }

                if (this.otp3 == null || this.otp3 == "") {
                    this.$refs.otp2.focus();
                }

                if (this.otp2 == null || this.otp2 == "") {
                    this.$refs.otp1.focus();
                }

                if (this.otp1 == null || this.otp1 == "") {
                    this.$refs.otp1.focus();
                }
            }
        },
        async success() {
            //this.$message.success(res.data.Message);
            await this.$store.dispatch("getUser");
        },
        async verifyOtp() {
            this.loading = true;
            try {
                var otpCode = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;

                var res = null
                if (this.isEmailValidation) {
                    res = await this.$client.post("/Account/ValidateEmailOtp", { Email: this.tempUser.Email, ConfirmationCode: otpCode });

                }
                else {
                    res = await this.$client.post("/Account/ValidatePhoneNumberOtp", { PhoneNumber: this.tempUser.PhoneNumber, ConfirmationCode: otpCode });
                }

                this.$notify({
                    title: res.data.HasError ? this.$localization("web-login-error") : "",
                    message: res.data.Message,
                    type: res.data.HasError ? "error" : "success",
                });

                if (res.data.HasError) {
                    this.$nextTick(() => {
                        this.otp1 = null;
                        this.otp2 = null;
                        this.otp3 = null;
                        this.otp4 = null;
                        this.otp5 = null;
                        this.otp6 = null;
                    });
                    this.$nextTick(async () => {
                        await this.$refs.otp1.focus();
                    });
                }
                else {
                    this.success(); // await ?

                    this.show = false;//notifydan dolayı hata çıkabilir.
                }

            } catch (err) {
                console.log(err);
            }



            this.loading = false;
        },
        handleClose() {
            this.show = false;
        },
    },
    watch: {
        otp1: function () {
            if (this.otp1 != null && this.otp1.length == 1) {
                this.$refs.otp2.focus();
            } else {
                this.$refs.otp1.focus();
            }
        },
        otp2: function () {
            if (this.otp2 != null && this.otp2.length == 1) {
                this.$refs.otp3.focus();
            } else {
                this.$refs.otp1.focus();
            }
        },
        otp3: function () {
            if (this.otp3 != null && this.otp3.length == 1) {
                this.$refs.otp4.focus();
            } else {
                this.$refs.otp2.focus();
            }
        },
        otp4: function () {
            if (this.otp4 != null && this.otp4.length == 1) {
                this.$refs.otp5.focus();
            } else {
                this.$refs.otp3.focus();
            }
        },
        otp5: function () {
            if (this.otp5 != null && this.otp5.length == 1) {
                this.$refs.otp6.focus();
            } else {
                this.$refs.otp4.focus();
            }
        },
        otp6: function () {
            if (this.otp6 != null && this.otp6.length == 1) {
                this.$refs.otp6.focus();
            } else {
                this.$refs.otp5.focus();
            }
        },
    },
};
</script>