<template>
    <div id="my-conversations">

        <div class="title">{{ $localization('web-home-prescriptions') }}</div>
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>{{ $localization('web-home-prescriptions') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="search-status">
            <div class="status">
                {{ $localization('web-home-show') }}
                <el-select class="primary-input input-bg-white" v-model="filter.PageSize" :placeholder="$localization('web-home-please-select')">
                    <el-option v-for="( item, index ) in  pagesize " :key="index" :label="item" :value="item"> </el-option>
                </el-select>
            </div>
        </div>

        <div class="table">
            <el-table :header-cell-class-name="'table-header'" :data="paginetedPerceptionAppointments">
                <el-table-column :label="$localization('web-appointment-no')">
                    <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
                </el-table-column>
                <el-table-column :label="$localization('web-views-client-details-prescriptions-no')">
                    <template slot-scope="scope"> {{ scope.row.PrescriptionsNo }} </template>
                </el-table-column>

                <el-table-column :label="$localization('web-interview-meet-date')">
                    <template slot-scope="scope">
                        <span v-if="scope.row.StartDate">{{ $moment(scope.row.StartDate).format("DD.MM.YYYY") }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div class="lottie-container">
                        <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                        <div class="no-data-text">{{ $localization('web-prescriptions-no-prescriptions-data') }}</div>
                        <div class="no-data-text">{{ $localization('web-prescriptions-prescriptions-will-show-in-this-area') }}</div>
                    </div>
                </template>
            </el-table>
        </div>


        <div class="pagination">
            <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > getPerceptionAppointments.length ? getPerceptionAppointments.length :
                filter.PageSize
                * filter.Page }} {{ $localization('web-pagination-info-text') }} {{ getPerceptionAppointments.length }}</div>
            <el-pagination class="pages" background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="getPerceptionAppointments.length" :small="screenWidth > 550 ? false : true"
                :pager-count="screenWidth > 550 ? 7 : 5"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        getPerceptionAppointments() {
            return this.$store.getters.getAppointments.Appointments.filter(function (element) { return element.PrescriptionsNo !== null; });
        },
        paginetedPerceptionAppointments() {
            return this.paginate(this.getPerceptionAppointments, this.filter.PageSize, this.filter.Page);
        }
    },
    data() {
        return {
            pagesize: [10, 20, 30, 40],
            screenWidth: 0,
            filter: {
                PageSize: 10,
                Page: 1,
            },
        };
    },
    async created() {
        await this.$store.dispatch("getListUserAppointments", this.filter);

    },
    mounted() {
        this.screenWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            this.screenWidth = window.innerWidth;
        });
    },
    methods: {
        paginate(array, page_size, page_number) {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        }
    }
}
</script>