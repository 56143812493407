import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  validationErrors: [],
  fileSearchFilter: {
    SearchUserId: null,
    CreatedAtMin: null,
    CreatedAtMax: null,
    FileCategoryId: null,
    Extensions: null,
    SearchTerm: null,
    PageSize: 100,
    Page: 1,
    Type: 0,
  },
  files: {
    Items: [],
    TotalCount: 0,
    PageCount: 0,
  },
  fileCategory: {
    Id: null,
    OrderNo: 0,
    Title: null,
    Color: null,
  },
  fileCategories: {
    Items: [],
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
