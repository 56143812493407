export default {
  setFiles(state, data) {
    state.files = data;
  },
  deleteFiles(state, idList) {
    state.files.Items = state.files.Items.filter((x) => !idList.includes(x.ID));
  },
  clearFiles(state) {
    state.files = {
      Items: [],
      TotalCount: 0,
      PageCount: 0,
    };
  },
  clearFileSearchFilter(state) {
    state.fileSearchFilter = {
      SearchUserId: null,
      CreatedAtMin: null,
      CreatedAtMax: null,
      FileCategoryId: null,
      Extensions: null,
      SearchTerm: null,
      PageSize: 100,
      Page: 1,
      Type: 0,
    };
  },
  setFileCategories(state, data) {
    state.fileCategories = data;
  },
  deleteFileCategory(state, data) {
    state.fileCategories.Items = state.fileCategories.Items.filter((x) => x.ID != data);
  },
  addOrUpdateFileCategory(state, data) {
    var index = state.fileCategories.Items.findIndex((x) => x.ID == data.ID);
    if (index >= 0) {
      state.fileCategories.Items[index] = data;
    } else {
      state.fileCategories.Items.push(data);
    }
  },
  setFileValidationErrors(state, data) {
    state.validationErrors = data;
  },
  setFileCategory(state, data) {
    state.fileCategory = data;
  },
};
