import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
export default {
  async getEnums(context) {
    var res = await client.get("/Definition/GetEnums");
    if (res.data.HasError) {
      console.log("getEnums error");
    } else {
      context.commit("setEnums", res.data.Data.Items);
    }
  },
  async getUser(context) {
    var res = await client.post("/Account/Get", {});
    if (res.data.HasError) {
      console.log("getUser Error");
    } else {
      await context.commit("setUser", res.data.Data);
    }
  },
  async setToken(context, payload) {
    await context.commit("setToken", payload);
  },
  async getNotification(context, payload) {
    var res = await client.post("/Notification/List", payload);
    if (res.data.HasError) {
      console.log("getUser Error");
    } else {
      context.commit("setNotification", res.data.Data);
    }
  },
  async loadNotification(context, payload) {
    var res = await client.post("/Notification/List", payload);
    if (res.data.HasError) {
      console.log("getUser Error");
    } else {
      context.commit("addNotification", res.data.Data.Notification);
    }
  },
  async setLanguageLocalisationKey(context, payload) {
    var res = await client.post("/Localization/GetLocalizationJson", { IsWeb: true, LanguageCode: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      localStorage.setItem(`localization`, JSON.stringify(res.data));
      localStorage.setItem(`language`, payload);
      context.commit("setLanguage", payload);
      context.commit("setLocalizationKeys", res.data);
    }
  },
  async getAdminStatistics(context, payload) {
    var res = await client.post("/Statistics/DashboardStatistics", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setStatistics", res.data.Data);
    }
  },
};
