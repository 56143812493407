<template>
    <div id="faq">
        <div class="title">{{ $localization('web-clientlayout-faq') }}</div>
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>{{ $localization('web-clientlayout-faq') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <FaqItemCom v-for="(item, index) in faqList" :key="index" :question="item.Title" :answer="item.Details" />
    </div>
</template>


<script>
import FaqItemCom from "@/components/FaqItem";
export default {
    components: {
        FaqItemCom,
    },
    computed: {
        filter() {
            return this.$store.getters.getFaqFilter;
        },
        faqList() {
            return this.$store.getters.getFaqList;
        },
    },
    data() {
        return {
            faqList1: [
                { question: "Soru 1aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", answer: "Cevap 1" },
                { question: "Soru 2", answer: "Cevap 2" },
                // Diğer sorular ve cevapları
            ],
        };
    },
    async beforeMount() {
           if (this.$gtag) {
            this.$gtag.pageview({ page_path: this.$route.path, page_title: 'Interview | FAQ' });
        }

        await this.$store.dispatch("getFaqList", this.filter);
    },
    mounted() {

    },
};
</script>

<style lang="less">
#faq {
    padding: 42px 30px;

    .title {
        font-size: 30px;
        color: #44566c;
        font-weight: 700;
        line-height: 35px;
        margin-bottom: 30px;
    }
}
</style>