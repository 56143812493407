<template>
  <div class="mobile-support" v-if="screenWidth < 1024 && !(this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.path.startsWith('/kullanici'))">
    <img src="./assets/img/logo.svg" width="140px" alt="" />
    <div class="text title">{{ $localization("web-app-welcome-elra") }}</div>
    <div class="text">{{ $localization("web-app-download-mobile-app") }}</div>
    <div class="store">
      <img src="./assets/img/google-play-button.svg" />
      <img src="./assets/img/app-store-button.svg" />
    </div>
  </div>
  <div id="app" v-else>
    <router-view />
  </div>
</template>

<style lang="less">
@import url("./assets/css/base.less");
</style>

<script>
export default {
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  async created() {
    this.$OneSignal.on("subscriptionChange", function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed);
    });
    this.$OneSignal.on("notificationDisplay", function (event) {
      console.warn("OneSignal notification displayed:", event);
    });
    this.$OneSignal.showNativePrompt();
  },
};
</script>
