<template>
    <div class="box">
        <div class="faq-item" @click="toggleAnswer">
            <div class="faq-title">
                <p>{{ question }}</p>
                <i class="icon-arrow" :class="{ 'arrow-up': isOpen, 'arrow-down': !isOpen }"></i>
            </div>

            <transition name="fade">
                <div class="description" :style="{ padding: isOpen ? '10px 10px' : '0' }">
                    <p v-if="isOpen" v-html="answer"></p>
                </div>

            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        answer: String,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleAnswer() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="less" scoped>
p {
    margin: 0;
}

.box {
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 15px;

    overflow: hidden;
    background-color: #eff4f8;
}

.faq-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    color: #44566c;
    margin: 0;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.description {
    background-color: #f8fafb;
}

.faq-item {
    cursor: pointer;
}

.icon-arrow {
    background-color: #44566c;
    transform: rotate(90deg);
}

.arrow-up {
    transform: rotate(-90deg);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>