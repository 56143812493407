import { client } from "@/helpers/axiosHelpers";
import { Message } from "element-ui";
import * as MutationNames from "../mutation-names";
import defaultVariables from "@/default/variables";
export default {
  async setPermissionCameraAndMicrophone(context, payload) {
    var res = await client.post("/Appointment/CameraAndMicPermission", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
  },
  async getListUserAppointments(context, payload) {
    var res = await client.post("/Appointment/List", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setUserAppointments", res.data.Data);
      return res.data.Data.Appointments;
    }
  },
  async getCurrentAppointmentMessages(context, payload) {
    var res = await client.post("/Appointment/ListMessages", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setCurrentAppoinmentMessage", res.data.Data.Items);
    }
  },
  async setDoctorNotes(context, payload) {
    var res = await client.post("/Appointment/UpdateDoctorDiagnosis", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
  },
  async getAppointmentPrice(context) {
    try {
      var res = await client.post("/Appointment/GetAppointmentPrice", { ApplyDiscount: false });
      if (res.data.HasError) {
        Message.error(res.data.Message);
      } else {
        context.commit(MutationNames.SET_APPOINTMENT_PRICE, res.data.Data);
        return res.data.Data;
      }
    } catch (error) {
      return defaultVariables.appointmentPrice;
    }
  },
  async getDiscountAppointmentPrice(context) {
    try {
      var res = await client.post("/Appointment/GetAppointmentPrice", { ApplyDiscount: true });
      if (res.data.HasError) {
        Message.error(res.data.Message);
      } else {
        context.commit(MutationNames.SET_DISCOUNT_APPOINTMENT_PRICE, res.data.Data);
        return res.data.Data;
      }
    } catch (error) {
      return defaultVariables.appointmentPrice;
    }
  },
  async getIsFree(context) {
    try {
      var res = await client.post("/Appointment/GetAppointmentIsFree");
      if (res.data.HasError) {
        Message.error(res.data.Message);
      } else {
        context.commit(MutationNames.SET_IS_FREE, res.data.Data);
        return res.data.Data;
      }
    } catch (error) {
      return defaultVariables.isFree;
    }
  },
  async getMobilePaymentEnabled(context) {
    try {
      var res = await client.post("/Appointment/GetMobilePaymentEnabled");
      if (res.data.HasError) {
        Message.error(res.data.Message);
        return false; // default false
      } else {
        context.commit(MutationNames.SET_MOBILE_PAYMENT_ENABLED, res.data.Data);
        return res.data.Data;
      }
    } catch (error) {
      return false;
    }
  },
  async getTaxRate(context) {
    try {
      var res = await client.post("/Appointment/GetAppointmentTaxRate");
      if (res.data.HasError) {
        Message.error(res.data.Message);
      } else {
        context.commit(MutationNames.SET_TAX_RATE, res.data.Data);
        return res.data.Data;
      }
    } catch (error) {
      return defaultVariables.taxRate;
    }
  },
};
