//#appointments
export const SET_APPOINTMENT_PRICE = "setAppointmentPrice";
export const SET_DISCOUNT_APPOINTMENT_PRICE = "setDiscountAppointmentPrice";
export const SET_IS_FREE = "setIsFree";
export const SET_TAX_RATE = "setTaxRate";
export const SET_MOBILE_PAYMENT_ENABLED = "setMobilePaymentEnabled";

//#appointments
//#definitions
export const SET_COUNTRY_LIST = "setCountryList";
export const SET_CITY_LIST = "setCityList";
export const SET_DISTRICT_LIST = "setDistrictList";
//#definitions
//#file-manager
export const SET_FILES = "setFiles";
export const DELETE_FILES = "deleteFiles";
export const CLEAR_FILES = "clearFiles";
export const CLEAR_FILE_SEARCH_FILTER = "clearFileSearchFilter";
export const SET_FILE_CATEGORIES = "setFileCategories";
export const DELETE_FILE_CATEGORY = "deleteFileCategory";
export const ADD_UPDATE_FILE_CATEGORY = "addOrUpdateFileCategory";
export const SET_FILE_VALIDATION_ERRORS = "setFileValidationErrors";
export const SET_FILE_CATEGORY = "setFileCategory";
//#file-manager
