import { client } from "@/helpers/axiosHelpers"
import { Message } from 'element-ui';
import * as MutationNames from "../mutation-names";
export default {
    async getCountryList(context) { //payload
        var res = await client.post("/Country/List", { SearchTerm: "", PageSize: 99999, Page: 1 });
        //var res = await client.post("/Country/List", payload);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit(MutationNames.SET_COUNTRY_LIST, res.data.Data.Items);
        }
    },
    async getCityList(context) {
        var res = await client.post("/City/List", { SearchTerm: "" });
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit(MutationNames.SET_CITY_LIST, res.data.Data.Items);
        }
    },
    async getDistrictList(context, id) {
        if (id == null || id == "") {
            context.commit(MutationNames.SET_DISTRICT_LIST, []);
            return;
        } else {
            var res = await client.post("/District/List", { SearchTerm: "", CityId: id });
            if (res.data.HasError) {
                Message.error(res.data.Message);
            } else {
                context.commit(MutationNames.SET_DISTRICT_LIST, res.data.Data.Items);
            }
        }
    },
};